// 6322422002- Store Details Migration
import React, { useContext, useEffect, useRef, useState, useCallback } from "react";
import { Card, CardFooter, CardHeader, Col, Container, Row, Table, Input, UncontrolledTooltip } from "reactstrap";
import { api } from "services/api";
import Moment from "react-moment";
import { removeAllURLParams, addURLParam } from "utils/urls";
import Select from "react-select";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";
import { useLocation, NavLink } from "react-router-dom";
import StoreWebhooks from "./store-webhooks";
import "./storeDetails.css";
import { useHistory } from "react-router-dom";
import "../../utils/commonStyles.css";
import dayjs from 'dayjs'
import AsyncCreatableSelect from 'react-select/async-creatable'
import AsyncSelect from 'react-select/async'

const StoreAdd = () => {
    const { addToast } = useToasts();
    const history = useHistory();
    const searchParams = new URLSearchParams(window.location.search);
    const storeId = searchParams.get("storeId");
    const [storeDetailsInfo, setStoreDetailsInfo] = useState({}); //main state
    //Dropdown Options States
    const [fulfillmentStateList, setFulfillmentStateList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [brandList, setBrandList] = useState([]);

    // Dropdown value states
    const [storeGroupAssociated, setStoreGroupAssociated] = useState([]);
    const [storeBrands, setStoreBrands] = useState([]);
    const [storeFulfillmentStates, setStoreFulfillmentStates] = useState([]);

    //Section Lists
    const [storeKeys, setStoreKeys] = useState([]); //Store key section(2)
    const [storeLocations, setStoreLocations] = useState([]); //Store Location section state(3)
    const [storeAccessScopes, setStoreAccessScopes] = useState([]); //Store Access Scopes section state(5)
    const [storeWebhooks, setStoreWebHooks] = useState([]); //Store Webhooks section state(4)

    //Section 1 States:
    const [section1Details, setSection1Details] = useState({
        name: "",
        code: "",
        shopifDomain: "",
        vanityURL: "",
        isActive: true,
        delayedEmails: true,
        transitionToWms: false,
        logoURL: "",
        wmsStartDate: null,
        integrationStartDate: new Date()
    });

    // const [flag, setFlag] = useState(false);


    const [error, setError] = useState(false);


    const storeFulfillmentStatesIds = storeFulfillmentStates.map(item => item.value);
    const storeBrandsIds = storeBrands.map(item => item.value);
    const storeGroupAssociatedIds = storeGroupAssociated?.value;

    //Default Radiobutton

    // 6639158808- Allow buy button toggle on store details page
    const [toggleStates, setToggleStates] = useState({
        buyButton: false
    });

    const [showBuyButton, setShowBuyButton] = useState();
    const [activateBuyButton, setActivateBuyButton] = useState();

    const [salesChannels, setSalesChannels] = useState([]);
    const [isTransitioned, setIsTransitioned] = useState(false)

    // Filter only the updated sales channels
    const updatedChannels = salesChannels.filter(channel => channel.isUpdated);

    // 7105856900 - Remove shopify_id from sales_channel while sending to api payload because shopify_id is non editable field
    const sanitizedSalesChannels = updatedChannels?.map(channel => {
        const { shopify_id, isUpdated, ...rest } = channel;
        return rest;
    });
    // 7406909952 - Add Store Key
    const [errors, setErrors] = useState({
        storeKeys: [] // Array to track errors for each store key
    });
    const [appOptions, setAppOptions] = useState([]);
    const [versionOptions, setVersionOptions] = useState([]);

    const storeKeysPayload = storeKeys
        .filter(storeKey => storeKey.key && storeKey.secret_key && storeKey.version) // Only include store keys with mandatory fields filled
        .map(storeKey => ({
            id: storeKey?.id || undefined, // Include id only if it exists (for update purposes)
            key: storeKey.key,
            secret_key: storeKey.secret_key,
            storefront_access_token: storeKey.storefront_access_token || "",
            app: storeKey?.app?.value,
            version: storeKey?.version?.value
        }));

    /**
     * Payload for PUT request for main form
     */
    const [payloadData, setPayloadData] = useState({
      name: '',
      code: '',
      domain: '',
      vanity_url: null,
      is_active: true,
      shopify_integration: null,
      billable: null,
      delayed_emails: true,
      logo_url: null,
      wms_start_date: null,
      integration_start_date: null,
      billable_user: null,
      group: null,
      store_keys: storeKeys,
      brands: [],
      local_fulfillment_state: []
    })

    /**
     * Handler for Submitting the form
     * @param {*} e
     */
    const handleSubmit = e => {
        e.preventDefault();
        console.log(payloadData)
        api.post(`store/create/`, payloadData).then(response => {
            const data = response.data;
            console.log(data);
            setErrors({})
            setError(false)
            history.push('/store');
        }).catch(error => {
            const data = error.response.data;

            setErrors(data)
            setError(true)

        })


    };



    const handleStoreGroupAssociated = newValue => {
        setStoreGroupAssociated(newValue);
    };

    {
        /**Handler for Brands dropdown */
    }
    const handleStoreBrands = newValue => {
        setStoreBrands(newValue);
    };


    const handleStoreFulfillmentStates = newValue => {
        setStoreFulfillmentStates(newValue);
    };

    /**
     * Function for handling change of the Input
     */
    const handleChange = useCallback(
        e => {
            setSection1Details({ ...section1Details, [e.target.id]: e.target.value });
        },
        [section1Details]
    );

    /**
     * Function for handling change of the delayed email checkbox
     */
    const handleChangeChecked = useCallback(
        e => {
            setSection1Details({ ...section1Details, [e.target.id]: e.target.checked });
        },
        [section1Details]
    );


    /**
     * Handle Store Delete Key
     * @param {*} storeId
     */
    const handleDeleteStoreKey = (storeKey, index) => {
        if (storeKey?.id && storeKey?.saved) {
            // If the store key has an id and has been saved, make the API call to delete it from the backend
            api.delete(`store/store-key/delete/${storeKey?.id}`)
                .then(response => {
                    addToast("Deleted Successfully", { appearance: "success", autoDismiss: true });

                    // Remove the deleted key from the UI
                    const updatedKeys = storeKeys.filter((_, i) => i !== index);
                    setStoreKeys(updatedKeys);
                })
                .catch(error => addToast(error.message, { appearance: "error" }));
        } else {
            // If the store key is not saved (no id), just remove it from the UI
            const updatedKeys = storeKeys.filter((_, i) => i !== index);
            setStoreKeys(updatedKeys);
        }
    };

    // 6639158808- Allow buy button toggle on store details page
    // Toggle handler
    const handleToggleChange = toggleName => e => {
        const newState = e.target.checked ? true : false;
        setActivateBuyButton(newState);
        setToggleStates(prevStates => ({
            ...prevStates,
            [toggleName]: newState
        }));
    };

    const isActiveOptions = [
        { value: true, label: "Yes" },
        { value: false, label: "No" }
    ];

    const handleIsActiveChange = option => {
        setSection1Details(prevState => ({ ...prevState, isActive: option.value }));
    };

    // 7105856900 - Handle function for sales channel name and slug update
    const handleSalesChannelChange = (index, field, value) => {
        const updatedChannels = [...salesChannels];
        updatedChannels[index] = { ...updatedChannels[index], [field]: value, isUpdated: true };
        setSalesChannels(updatedChannels);
    };
    // 7406909952 - Add Store Key
    // Function to handle adding a new key
    const handleAddNewStoreKey = () => {
        setStoreKeys(prevKeys => [...prevKeys, { key: "", secret_key: "", storefront_access_token: "", app: "", version: "", saved: false }]);
    };

    // Function to handle input changes
    const handleInputChange = (index, field, value) => {
        const updatedKeys = [...storeKeys];
        updatedKeys[index] = {
            ...updatedKeys[index],
            [field]: value // Dynamically update the field (key, secret_key, etc.)
        };
        setStoreKeys(updatedKeys);
    };

    useEffect(() => {
        api.get(`store/storekey/version/`).then(response => {
            const availableVersions = response?.data || [];
            setVersionOptions(availableVersions); // Do not add "2023-01" to options
        });
    }, []);

    useEffect(() => {
        api.get(`store/storekey/app/`).then(response => {
            setAppOptions(response?.data);
        });
    }, []);

    const getStates = async () => {
        try {
            const response = await api.get('account/state/');
            const data = response?.data || [];

            setFulfillmentStateList(data.results.map(item => {
                return { 'value': item.id, 'label': item.name };
            }));
        } catch (error) {
            console.error('Erro ao buscar os estados', error);
        }
    };

    const getGroup = async () => {
        try {
            const response = await api.get('store/group/');
            const data = response?.data || [];

            setGroupList(data.results.map(item => {
                return { 'value': item.id, 'label': item.name };
            }));
        } catch (error) {
            console.error('Erro ao buscar os grupos', error);
        }
    };

    const mapResponseToValuesAndLabels = (data) => ({
        value: data.id,
        label: data.name,
    });

    async function getBrands(value) {
        let updatedBrands = [
            { value: "__all__", label: " All Brands" },
            { value: "no", label: "No Brands" }
        ];

        let data = await api.get(`brand/?q=${value}`)
          .then((response) => {
              console.log(response.data)
              return response.data
          })
          .then((response) => response?.results.map(mapResponseToValuesAndLabels))
          .then((final) =>
            final.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))
          );
        data = updatedBrands.concat(data)
        return data;
    }

    useEffect(() => {
        const fetchData = async () => {
            await getStates(); // Chama a função que faz a requisição
        };

        const fetchGroup = async () => {
            await getGroup(); // Chama a função que faz a requisição
        };

        fetchData();
        fetchGroup();

    }, [])

    useEffect(() => {
        console.log("fulfillmentStateList", storeFulfillmentStates)
        console.log("payload", payloadData)
        const idStates = storeFulfillmentStates.map(item=>item.value)
        setPayloadData(prevState => ({
            ...prevState,
            local_fulfillment_state: idStates
        }))
    }, [storeFulfillmentStates])

    useEffect(() => {
        console.log("storeBrands", storeBrands)
        console.log("payload", payloadData)
        const ids = storeBrands.map(item=>item.value)
        console.log(ids)
        setPayloadData(prevState => ({
            ...prevState,
            brands: ids
        }))
    }, [storeBrands])

    useEffect(() => {
        console.log(storeKeys)
        setPayloadData(prevState => ({
            ...prevState,
            store_keys: storeKeys
        }))
    }, [storeKeys])

    useEffect(() => {
        console.log(errors)
    }, [errors])
    return (
        <Container fluid>
            <div className={"pt-7"} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <>
                    <h2 className="mb-0">Add Store</h2>
                    <div style={{ width: 1110 }}>
                        <Card className="shadow mt-3" style={{}}>
                            <CardHeader className="border-0">
                                <div>
                                    Name *
                                    <Input id="name"
                                           value={payloadData?.name}
                                           className="input-group input-group-alternative"
                                           onChange={(event)=>{
                                               setPayloadData(prevState => ({
                                                   ...prevState,
                                                   name: event.target.value
                                               }));
                                           }}/>
                                    {error === true &&
                                       <div
                                        style={{ color: 'red' }}>{errors?.name}</div>}
                                </div>
                                <div>
                                    Code *
                                    <Input id="code"
                                           value={payloadData.code}
                                           className="input-group input-group-alternative"
                                           onChange={(event)=>{
                                               setPayloadData(prevState => ({
                                                   ...prevState,
                                                   code: event.target.value
                                               }));
                                           }}/>
                                    {error === true && <div
                                        style={{ color: 'red' }}>{errors?.code}</div>}
                                </div>
                                <div>
                                    Shopify Domain *
                                    <Input
                                      id="shopifDomain"
                                      value={payloadData.domain}
                                      className="input-group input-group-alternative"
                                      onChange={(event)=>{
                                          setPayloadData(prevState => ({
                                              ...prevState,
                                              domain: event.target.value
                                          }));
                                      }}/>


                                    {error === true && (
                                        <div
                                          style={{ color: 'red' }}>{errors?.domain}</div>
                                      )}
                                </div>
                                <div>
                                    Vanity URL
                                    <Input
                                        id="vanityURL"
                                        value={payloadData.vanity_url}
                                        className="input-group input-group-alternative"
                                        onChange={(event)=>{
                                            setPayloadData(prevState => ({
                                                ...prevState,
                                                vanity_url: event.target.value
                                            }));
                                        }}/>
                                </div>
                                <div>
                                    Is Active
                                    <Select
                                      options={isActiveOptions}
                                      defaultValue={payloadData.is_active}
                                      onChange={(option)=>{
                                          setPayloadData(prevState => ({
                                              ...prevState,
                                              is_active: option.value
                                          }));
                                      }}/>

                                </div>
                                <div>
                                    Brands
                                    {/*<Select options={brandList} isMulti*/}
                                    {/*        value={storeBrands}*/}
                                    {/*        onChange={handleStoreBrands}/>*/}
                                    <AsyncSelect
                                      cacheOptions
                                      placeholder="Select Brands... "
                                      isMulti
                                      loadOptions={getBrands}
                                      value={storeBrands}
                                      onChange={handleStoreBrands}
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={false}
                                      allowSelectAll={true}
                                      defaultOptions
                                    />
                                </div>
                                <br/>
                                <div className="ml-3">
                                    <Input type="checkbox" id="delayedEmails"
                                           checked={payloadData.delayed_emails}
                                           onChange={(event)=>{
                                               setPayloadData(prevState => ({
                                                   ...prevState,
                                                   delayed_emails: event.target.value
                                               }));
                                           }}/>
                                    Delayed Emails
                                </div>
                                <br/>
                                <div>
                                    Local Fulfillment State
                                    <Select options={fulfillmentStateList}
                                            isMulti
                                            value={storeFulfillmentStates}
                                            onChange={handleStoreFulfillmentStates}/>
                                </div>
                                <br/>
                                Logo URL
                                <Input
                                    id="logoURL"
                                    type="textarea"
                                    rows="5"
                                    className="input-group input-group-alternative"
                                    value={payloadData.logo_url}
                                    onChange={(event)=>{
                                        setPayloadData(prevState => ({
                                            ...prevState,
                                            logo_url: event.target.value
                                        }));
                                    }}/>{" "}
                                <div>
                                    WMS Start Date
                                    <Input
                                      id="wmsStartDate"

                                      value={payloadData.wms_start_date ? dayjs(payloadData.wms_start_date).format("YYYY-MM-DD") : null}
                                      type="date"
                                      className="input-group input-group-alternative"
                                      onChange={(event)=>{
                                          setPayloadData(prevState => ({
                                              ...prevState,
                                              wms_start_date: event.target.value
                                          }));
                                      }}/>
                                </div>
                                <div>
                                    Integration Start Date
                                    <Input
                                      id="integrationStartDate"
                                      value={payloadData.integration_start_date}
                                      type="date"
                                      className="input-group input-group-alternative"
                                      onChange={(event)=>{
                                          setPayloadData(prevState => ({
                                              ...prevState,
                                              integration_start_date: event.target.value
                                          }));
                                      }}/>
                                    {error === true &&
                                       (
                                        <div
                                          style={{ color: 'red' }}>{errors?.integrationStartDate}</div>
                                      )}
                                </div>
                                <div>
                                    Group
                                    <Select id="group" options={groupList}
                                            value={storeGroupAssociated}
                                            onChange={handleStoreGroupAssociated}/>
                                </div>

                            </CardHeader>
                        </Card>



                        <Card className="shadow mt-3" style={{}}>
                            <CardHeader className="border-0">
                                <h3>Store Keys</h3>
                                <hr />
                                {error === true && (
                                  <div
                                    style={{ color: 'red', textAlign: 'center' }}>{errors?.store_keys?.non_field_errors}</div>
                                )}
                                {storeKeys?.length > 0 ? (
                                    storeKeys?.map((storeKey, index) => (
                                        <>
                                            <div key={index} className="store-key-row">
                                                <div
                                                    className="control-label text-sm"
                                                    style={{ display: "flex", flexDirection: "column", padding: "0px 10px" }}>
                                                    <>Key *</>
                                                    <>
                                                        <Input
                                                            value={storeKey?.key}
                                                            type="key"
                                                            className="input-group input-group-alternative"
                                                            onChange={e => handleInputChange(index, "key", e.target.value)}
                                                        />

                                                    </>
                                                </div>
                                                <div
                                                    className="control-label text-sm"
                                                    style={{ display: "flex", flexDirection: "column", padding: "0px 10px" }}>
                                                    <>Access Token *</>
                                                    <>
                                                        <Input
                                                            value={storeKey?.secret_key}
                                                            type="access token"
                                                            className="input-group input-group-alternative"
                                                            onChange={e => handleInputChange(index, "secret_key", e.target.value)}
                                                        />

                                                    </>
                                                </div>
                                                <div
                                                    className="control-label text-sm"
                                                    style={{ display: "flex", flexDirection: "column", padding: "0px 10px" }}>
                                                    <>Storefront Access Token</>
                                                    <>
                                                        <Input
                                                            value={storeKey?.storefront_access_token}
                                                            type="storefront access token"
                                                            className="input-group input-group-alternative"
                                                            onChange={e => handleInputChange(index, "storefront_access_token", e.target.value)}
                                                        />
                                                    </>
                                                </div>
                                                <div
                                                    className="control-label text-sm"
                                                    style={{ display: "flex", flexDirection: "column", flex: 1, padding: "0px 10px" }}>
                                                    <div>App</div>
                                                    <>
                                                        <Select
                                                            className="input-group input-group-alternative"
                                                            value={appOptions.find(option => option.value === storeKey.app.value)} // Set selected value
                                                            options={appOptions}
                                                            onChange={selectedOption => handleInputChange(index, "app", selectedOption)}
                                                        />
                                                    </>
                                                </div>
                                                <div
                                                    className="control-label text-sm"
                                                    style={{ display: "flex", flexDirection: "column", flex: 1, padding: "0px 10px" }}>
                                                    <>Version *</>
                                                    <>
                                                        <Select
                                                            className="input-group input-group-alternative"
                                                            value={
                                                                // Check if the current storeKey version exists in options, otherwise show "2023-01"
                                                                versionOptions.find(option => option.value === storeKey.version.value) ||
                                                                // If storeKey has "2023-01", display it but don't include it in options
                                                                (storeKey.version.value === "2023-01" ? { value: "2023-01", label: "2023-01" } : null)
                                                            }
                                                            options={versionOptions} // Do not include "2023-01" in options
                                                            onChange={selectedOption => handleInputChange(index, "version", selectedOption)}
                                                        />


                                                    </>
                                                </div>
                                                <button className="btn btn-outline-danger btn-sm mr-0" onClick={() => handleDeleteStoreKey(storeKey, index)}>
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </div>
                                        </>
                                    ))
                                ) : (
                                    <p>No Store Keys available</p>
                                )}

                                {/* Button to add new store key */}
                                <div className="text-center">
                                    <button className="btn btn-sm btn-outline-primary" onClick={handleAddNewStoreKey}>
                                        <i className="fas fa-plus"></i> Add Key
                                    </button>
                                </div>

                                <hr />

                            </CardHeader>
                        </Card>


                        <hr />
                        <div className="submit-back-btn-at-bottom">
                            <NavLink to={"/store"} style={{ cursor: "pointer" }}>
                                <a className="btn btn-lg">
                                    <i className="fas fa-arrow-left"> Back</i>
                                </a>
                            </NavLink>
                            <button className="btn btn-success btn-lg" onClick={handleSubmit}>
                                Submit <i className="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </>
            </div>
        </Container>
    );
};
export default StoreAdd;
