/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import OrderPageListing from "views/order";
import RetailerBilling from "views/retailer/billing";
import StagingListing from "./views/retailer-staging/list";
import ProductListing from "./views/product/list";
import ProductLocator from "./views/product-locator/list";
import InventoryListing from "./views/product-inventory/list";
import Chart from "./views/chart/chart";
import RetailerOverride from "./views/retailer/retailer-override";
import InventoryLocation from "./views/inventoryLocation/";
import ProductLocatorTokenGenerator from "views/inventoryLocation/generate";
import RetailerPayoutOverride from "./views/retailer/retailer-payout-override";
import ShippingChargebacks from "./views/Chargebacks/shipping-chargeback";
import Availability from "./views/product/availability";
import Fulfillment from "./views/retailer/fulfillment";
import SettingsInventoryLocation from "views/settings/inventoryLocation";
import MarketingCampaignListing from "views/marketing-campaign/list";
import CreateMarketingCampaign from "views/marketing-campaign/create";
import Dashboard from "./views/dashboard/dashboard";
import Customer from "views/customer/list";
import DiscountCodeChargebacks from "./views/discount-code-billing/index";
import InvoiceChargeBacks from "views/InvoiceChargeBack/invoice-chargebacks";
import JoseCuervoInventory from "./views/brand-inventory/JoseCuervoInventory"
// import OrderStatusOverride from "views/order/OrderStatusOverride";
import AdventCalendar from "components/Order/AdventCalendar";
import AdventBatch from "components/Order/AdventBatch";
import OrderStatusOverride1 from "views/order/OrderStatusOverride";
import DiscountCodeChargebacksInvoice from "views/InvoiceDiscountCodeChargeBack/discount-code-chargeback-invoice";
import UsersListingPage from "./views/users";
import ManualChildOrders from "views/F3/ManualChildOrders";
import CreateUser from "./views/users/CreateUser";
import UpdateUser from "./views/users/UpdateUser";
import ArchiveOrderListing from "views/ArchiveOrders/archive-orders";
import StoreListing from "views/store/store";
import List from "views/subscription/List"
import RoleBasedRoute from "RoleBasedRoute";
import BulkOrderListingPage from "views/corporate/BulkOrderListingPage"
import CreateProduct from "views/product/create-product/CreateProduct";
import ProductDetails from "views/product/view-product/ProductDetails";
import UpdateProduct from "views/product/update-product/UpdateProduct";
import StoreDetails from "views/store/store-details";
import BulkOrdersDetails from "./views/bulk-orders/BulkOrdersDetails";
import OrderDetailsPage from "views/order/order-details-page/OrderDetailsPage";
import UserProfile from "./views/user-profile/UserProfile";
import OptimizedOrderPageListing from "views/optimisedOrder";
import ComboSkusListing from './views/product/combo-skus/list'
import BrandOrderMonitoring from "components/Order/BrandOrderMonitoring";
import SubscriptionDetail from './views/subscription/detail';
import CustomerDetail from "./views/customer/CustomerDetail";
import StoreAdd from './views/store/store-add'
import StoreStandardRateListing from "views/store/store-standard-rate-listing";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component:() => <RoleBasedRoute><Dashboard/></RoleBasedRoute>,
    layout: "/",
  },

{
    path: "/charts/",
    name: "Charts",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><Chart/></RoleBasedRoute>,
    layout: "/",
  },

    {
    path: "/billing/",
    name: "Retailer Billing",
    icon: "ni ni-cart text-primary",
    component:()=><RoleBasedRoute><RetailerBilling/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/retailer-staging/",
    name: "Staging",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><StagingListing/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/product-inventory/",
    name: "Product",
    icon: "ni fa-dolly text-primary",
    component:() => <RoleBasedRoute><InventoryListing/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/product-locator/",
    name: "Product",
    icon: "ni fa-dolly text-primary",
    component:() => <RoleBasedRoute><ProductLocator/></RoleBasedRoute>,
    layout: "/",
  },

  {
    path: "/product-availability/",
    name: "Product",
    icon: "ni fa-accusoft text-primary",
    component:() => <RoleBasedRoute><Availability/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/retailer-override/",
    name: "Retailer Override",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><RetailerOverride/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/retailer-payout-override/",
    name: "Retailer Billing Override",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><RetailerPayoutOverride/></RoleBasedRoute>,
    layout: "/",
  },
  
  {
    path: "/product/",
    name: "Product",
    icon: "ni fa-dolly text-primary",
    component:() => <RoleBasedRoute><ProductListing/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/shipping-chargebacks/",
    name: "Marketing Campaigns",
    icon: "ni fa-dolly text-primary",
    component:() => <RoleBasedRoute><ShippingChargebacks/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/inventory-location",
    name: "Inventory Location",
    icon: "ni fa-dolly text-primary",
    component:() => <RoleBasedRoute><InventoryLocation/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/inventory-locator-integration",
    name: "Inventory Locator Integration",
    icon: "ni fa-dolly text-primary",
    component:() => <RoleBasedRoute><ProductLocatorTokenGenerator/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/shipping-chargebacks/",
    name: "Marketing Campaigns",
    icon: "ni fa-dolly text-primary",
    component:() => <RoleBasedRoute><ShippingChargebacks /></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/fulfillment",
    name: "Fulfillment",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><Fulfillment/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/settings-inventory-location",
    name: "Inventory Location",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><SettingsInventoryLocation/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/marketing-campaign",
    name: "Marketing Campaign",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><MarketingCampaignListing/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path:"/create-marketing-campaign",
    name: "Create Marketing Campaign",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><CreateMarketingCampaign/></RoleBasedRoute>,
    layout: "/"
  },
  {
    path: "/customers",
    name: "Customer",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><Customer/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/shipping-invoice-chargebacks",
    name: "Invoice Chargebacks",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><InvoiceChargeBacks/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/discount-code-invoice-chargebacks",
    name: "Discount Code Invoice Chargebacks",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><DiscountCodeChargebacksInvoice/></RoleBasedRoute>,
    layout: "/",
  },
  // 6355048100- Archive orders Route 
  {
    path: "/archived-orders",
    name: "Archive Orders",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><ArchiveOrderListing/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/orders",
    name: "Order",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><OrderPageListing/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/discount-code-chargebacks/",
    name: "Discount Code Chargebacks",
    icon: "ni fa-dolly text-primary",
    component:() => <RoleBasedRoute><DiscountCodeChargebacks/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/jose-cuervo-inventory",
    name: "Jose Cuervo Inventory",
    icon: "fas fa-cogs text-primary",
    component:() => <RoleBasedRoute><JoseCuervoInventory/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/advent-calendar",
    name: "Advent Calendar",
    icon: "fas fa-cogs text-primary",
    component:()=><RoleBasedRoute><AdventCalendar/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/advent-batch",
    name: "Advent Batch",
    icon: "fas fa-cogs text-primary",
    component:()=><RoleBasedRoute><AdventBatch/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/order-status-override/",
    name: "Order status override",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><OrderStatusOverride1/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/users",
    name: "Users",
    icon: "fas fa-cogs text-primary",
    component:()=><RoleBasedRoute><UsersListingPage/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/manual-child-orders",
    name: "Manual Child Orders",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><ManualChildOrders/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path:"/user/add/",
    name: "Create User",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><CreateUser/></RoleBasedRoute>,
    layout: "/"
  },
  {
    path:"/user-change",
    name: "Update User",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><UpdateUser/></RoleBasedRoute>,
    layout: "/"
  },
  {
    path: "/store",
    name: "Store",
    icon: "ni ni-cart text-primary",
    component: () => <RoleBasedRoute><StoreListing/></RoleBasedRoute>,
    layout: "/",
  },
  // Monday Item - 6586117328
  {
    path: "/subscription",
    name: "subscription",
    icon: "ni ni-cart text-primary",
    component: () => <RoleBasedRoute><List/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/bulk-orders",
    name: "bulk orders",
    icon: "ni ni-cart text-primary",
    component: () => <RoleBasedRoute><BulkOrderListingPage/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path:"/store-details",
    name: "Store Details",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><StoreDetails /></RoleBasedRoute>,
    layout: "/"
  },
  {
    path: "/create-product",
    name: "create-product",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><CreateProduct /></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/product-details",
    name: "product-details",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><ProductDetails /></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/update-product",
    name: "update-product",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><UpdateProduct /></RoleBasedRoute>,
    layout: "/",
  }, 
  // Monday Item - 6586117328
  {
    path: "/subscription",
    name: "subscription",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><List /></RoleBasedRoute>,
    layout: "/",
  },
  {
    path: "/subscription-detail",
    name: "subscription detail",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><SubscriptionDetail /></RoleBasedRoute>,
    layout: "/",
  },
  {
    path:"/profile",
    name: "User Profile",
    icon: "ni ni-cart text-primary",
    component: UserProfile,
    layout: "/"
  },
  {
    path:"/store-details",
    name: "Store Details",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><StoreDetails /></RoleBasedRoute>,
    layout: "/"
  },
  {
    path:"/combo-skus",
    name: "Store Details",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><ComboSkusListing /></RoleBasedRoute>,
    layout: "/"
  },
  {
    path: "/optimized-orders",
    name: "Optimized Order",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><OptimizedOrderPageListing/></RoleBasedRoute>,
    layout: "/",
  },
  {
    path:"/order-details",
    name: "Order Details",
    icon: "ni ni-cart text-primary",
    component: OrderDetailsPage,
    layout: "/"
  },
  {
    path:"/brand-order-monitoring",
    name: "Brand Order Monitoring",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><BrandOrderMonitoring /></RoleBasedRoute>,
    layout: "/"
  },
  {
    path:"/customer-details",
    name: "Customer Details",
    icon: "ni ni-cart text-primary",
    component:() => <CustomerDetail />,
    layout: "/"
  },
  {
    path:"/bulk-order-details",
    name: "Bulk Order Details",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><BulkOrdersDetails /></RoleBasedRoute>,
    layout: "/"
  },
  {
    path:"/store-add",
    name: "Store Details",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><StoreAdd /></RoleBasedRoute>,
    layout: "/"
  },
  {
    path:"/store-standard-rate-listing",
    name: "Store Standard Rate Listing",
    icon: "ni ni-cart text-primary",
    component:() => <RoleBasedRoute><StoreStandardRateListing /></RoleBasedRoute>,
    layout: "/"
  },
];
export default routes;
