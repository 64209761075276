import React, { useState, useEffect } from "react";
import { api } from "services/api";
import { Container } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

const CustomerDetail = () => {
    const { addToast } = useToasts();
    const history = useHistory();
    const searchParams = new URLSearchParams(window.location.search);
    const customerId = searchParams.get("customerId");

    const [customer, setCustomer] = useState(null);
    const [ordersCount, setOrdersCount] = useState(0);
    const [totalSpent, setTotalSpent] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCustomerData = async () => {
            try {
                const response = await api.get(`customer/${customerId}/details`);
                setCustomer(response?.data?.customer);
                setOrdersCount(response?.data?.orders_count || 0);
                setTotalSpent(response?.data?.total_spent ?? 0);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching customer data:", error?.response);
                let noCustomerFlag = error?.response?.data?.no_customer;
                if (noCustomerFlag) {
                    addToast("No customer found with this ID", { appearance: "error", autoDismiss: true });
                } else {
                    addToast("Error fetching customer data", { appearance: "error", autoDismiss: true });
                }
                // Redirect to customers page in case of an error
                setTimeout(() => {
                    history.push("/customers");
                }, 3000);
            }
        };

        fetchCustomerData();
    }, [addToast, customerId, history]);

    return (
        <Container fluid style={{ paddingTop: "7rem" }}>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <div className="row">
                        {/* Left Column - Customer Details */}
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-header">
                                    <h3>Customer Detail</h3>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="form-group row">
                                            <h5 className="col-3 font-weight-bold">Name</h5>
                                            <div className="col-9">
                                                <input type="text" className="form-control" value={customer?.name || "-"} readOnly />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <h5 className="col-3 font-weight-bold">Email</h5>
                                            <div className="col-9">
                                                <input type="text" className="form-control" value={customer?.email || "-"} readOnly />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <h5 className="col-3 font-weight-bold">Phone</h5>
                                            <div className="col-9">
                                                <input type="text" className="form-control" value={customer?.phone || "-"} readOnly />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="card mt-4">
                                <div className="card-header">
                                    <h3>Address Info</h3>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="form-group row">
                                            <h5 className="col-sm-3 font-weight-bold">Address 1</h5>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" value={customer?.address1 || "-"} readOnly />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <h5 className="col-sm-3 font-weight-bold">Address 2</h5>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" value={customer?.address2 || "-"} readOnly />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-6 d-flex align-items-center p-0">
                                                <h5 className="col-sm-6 mr-3 font-weight-bold">Zip</h5>
                                                <input type="text" className="form-control" value={customer?.zip || "-"} readOnly />
                                            </div>
                                            <div className="col-sm-6 d-flex align-items-center p-0">
                                                <h5 className="col-sm-6 font-weight-bold">Company</h5>
                                                <input type="text" className="form-control" value={customer?.company || "-"} readOnly />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <h5 className="col-sm-3 font-weight-bold">City</h5>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" value={customer?.city || "-"} readOnly />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-6 d-flex align-items-center p-0">
                                                <h5 className="col-sm-6 mr-3 font-weight-bold">State</h5>
                                                <input type="text" className="form-control" value={customer?.state || "-"} readOnly />
                                            </div>
                                            <div className="col-sm-6 d-flex align-items-center p-0">
                                                <h5 className="col-sm-6 font-weight-bold">Country</h5>
                                                <input type="text" className="form-control" value={customer?.country || "-"} readOnly />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 mt-4 mt-lg-0">
                            <div className="card">
                                <div className="card-header">
                                    <h3>Customer Settings</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <>
                                            <h5 className="col-8 mb-4 d-flex flex-column justify-content-center font-weight-bold">Email Opt-In</h5>
                                            <div
                                                className={`col-4 font-weight-bolder mb-4 p-2 ${customer.accepts_marketing ? "text-success" : "text-danger"}`}
                                                style={{ backgroundColor: "#fafafc", fontSize: "1rem" }}>
                                                {customer?.accepts_marketing ? "Yes" : "No"}
                                            </div>
                                        </>

                                        <>
                                            <h5 className="col-8 mb-4 d-flex flex-column justify-content-center font-weight-bold">
                                                Orders Count ({ordersCount || 0})
                                            </h5>
                                            <div className="col-4 font-weight-bolder mb-4 p-2" style={{ backgroundColor: "#fafafc" }}>
                                                {customer?.order_navigation_details?.show_regular_button && ordersCount > 0 ? (
                                                    <a
                                                        href={`/orders?search=${customer.email}`}
                                                        className="btn btn-outline-primary btn-sm btn-block font-weight-bolder">
                                                        See Orders
                                                    </a>
                                                ): (
                                                    <button className="btn btn-outline-secondary btn-sm btn-block font-weight-bolder d-none">
                                                        See Orders
                                                    </button>
                                                )}

                                                {customer?.order_navigation_details?.show_archived_button && (
                                                    <a
                                                        href={`/archived-orders?search=${customer.email}`}
                                                        className="btn btn-outline-warning btn-sm btn-block font-weight-bolder mt-2 ml-0">
                                                        See Archived Orders
                                                    </a>
                                                )}
                                            </div>
                                        </>

                                        <>
                                            <h5 className="col-8 mb-4 d-flex flex-column justify-content-center font-weight-bold">Total Spent</h5>
                                            <div className="col-4 font-weight-bolder p-2" style={{ backgroundColor: "#fafafc" }}>
                                                ${isNaN(totalSpent) ? "0.00" : parseFloat(totalSpent).toFixed(2)}
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="py-4">
                        <div className="d-flex justify-content-between">
                            <a href="/customers" className="btn btn-lg">
                                <i className="fas fa-arrow-left"></i> Back
                            </a>
                        </div>
                    </div>{" "}
                </>
            )}
        </Container>
    );
};

export default CustomerDetail;
