/*

  Author - Akshay Vishwakarma
  Monday Id - 6618268581
  Description - This is the main page for creating and updating product.
  
 */

import React, { useEffect, useState } from "react";
import styles from "./createProduct.module.css";
import { Container, Spinner } from "reactstrap";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import { useToasts } from "react-toast-notifications";
import { api } from "services/api";
import { useHistory } from "react-router-dom";
import { stateListOptions } from "../../../utils/constants";
import SectionTwoUpdate from "../update-product/SectionTwoUpdateProduct";
import ReasonForChange from "../update-product/ReasonForChange";

const fetchDataAndTransform = async (endpoint, transformFn, setterFn, name) => {
    try {
        const response = await api.get(endpoint);
        const transformedData = response?.data?.map(transformFn);
        setterFn(prevState => ({
            ...prevState,
            [name]: transformedData
        }));
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

const CreateProduct = ({ updateProduct = false, updatePayload, productId }) => {
    const history = useHistory();
    const { addToast } = useToasts();
    const [variant_images, setVariant_images] = useState([]);
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const [requiredFields, setRequiredFields] = useState({
        title: false,
        abv: false,
        brand: false,
        product_category: false,
        product_type: false,
        options: [],
        srp: [],
        state: [],
        distributor: [],
        reason_of_change: false,
        abv_limit_exceeded: false
    });

    const [errors, setErrors] = useState({});
    const [listing, setListing] = useState({
        brandList: [],
        productCategoryList: [],
        productTypeList: [],
        tagsList: [],
        sizeList: [],
        stateList: stateListOptions,
        distributorList: []
    });

    const [payload, setPayload] = useState({
        title: "",
        description: "",
        abv: "",
        brand: "",
        newlyCreatedBrand: null,
        product_category: "",
        product_type: "",
        upc: "",
        explanation: "",
        new_product_type: null,
        tags: [],
        variant_size_for_images: [],
        productValue: [],
        images: [],
        disabledOptions: [],
        product_details: [
            {
                id: Date.now(),
                size: [],
                new_size: null,
                online_srp: "",
                weight: "", // 6943725569 - Added weight field on variant level
                sku: "",
                upc: "",
                available_qty: "",
                is_engravable: false,
                is_fulfillable: false,
                distribution_cost: [
                    {
                        id: Date.now() + 1,
                        state: [],
                        cost_to_retailer: 0,
                        qty_limit: 0,
                        distributor: [],
                        new_distributor: null
                    }
                ]
            }
        ]
    });

    console.log("payload", payload);
    const preprocessPayload = payload => {
        const preprocessArray = array => array.map(item => item.value);
        const preprocessArrayForLabel = array => array.map(item => item.label);
        const preprocessObject = item => item?.value ?? null;

        const productDetails = payload.product_details.map(product => ({
            ...product,
            new_size: product.new_size && typeof product.new_size === "object" ? preprocessObject(product.new_size) : null,
            size:
                product.size && !product.new_size && typeof product.size === "object"
                    ? preprocessObject(product.size)
                    : !product.new_size
                    ? product.size
                    : null,
            weight: product?.weight ? parseFloat(product?.weight) : null, // 6943725569 - Added weight field on variant level
            distribution_cost: product.distribution_cost.map(cost => ({
                ...cost,
                state: cost.state.length ? preprocessArray(cost.state) : [],
                distributor: !cost.new_distributor ? preprocessObject(cost.distributor) : null,
                new_distributor: cost.new_distributor && typeof cost.new_distributor === "object" ? preprocessObject(cost.new_distributor) : ""
            }))
        }));

        return {
            ...payload,
            brand: payload.newlyCreatedBrand === null || payload.newlyCreatedBrand === "" ? preprocessObject(payload.brand) : null,
            //new_product_type: payload.new_product_type && typeof payload.new_product_type === "object" ? preprocessObject(payload.brand) : null,
            tags: payload.tags ? preprocessArrayForLabel(payload.tags) : [],
            newlyCreatedBrand: payload.newlyCreatedBrand && typeof payload.newlyCreatedBrand === "object" ? preprocessObject(payload.newlyCreatedBrand) : "",
            product_type: payload.product_type && typeof payload.product_type === "object" ? preprocessObject(payload.product_type) : payload.product_type,
            product_details: productDetails
        };
    };

    const validatePayload = payload => {
        console.log('variant_images: ', variant_images);
        const isFieldEmpty = field => !field || field === "";
        const isArrayEmpty = array => !array || array.length === 0;

        let hasEmptyRequiredFields = false;
        const validationErrors = [];

        // Title
        if (isFieldEmpty(payload.title)) {
            requiredFields.title = true;
            hasEmptyRequiredFields = true;
        } else {
            requiredFields.title = false;
        }

        // ABV
        if (isFieldEmpty(payload.abv)) {
            requiredFields.abv = true;
            hasEmptyRequiredFields = true;
        } else if (parseInt(payload.abv) > 79) {
            requiredFields.abv = false;
            requiredFields.abv_limit_exceeded = true;
        } else {
            requiredFields.abv = false;
        }

        // Brand
        if (!payload.brand) {
            if (isFieldEmpty(payload.newlyCreatedBrand)) {
                requiredFields.brand = true;
                hasEmptyRequiredFields = true;
            } else {
                requiredFields.brand = false;
            }
        } else {
            requiredFields.brand = false;
        }

        // Product Category and Product Type
        if (isFieldEmpty(payload.product_category)) {
            requiredFields.product_category = true;
            hasEmptyRequiredFields = true;
        } else {
            requiredFields.product_category = false;
        }

        if (!payload.product_type) {
            if (isFieldEmpty(payload.new_product_type)) {
                requiredFields.product_type = true;
                hasEmptyRequiredFields = true;
            } else {
                requiredFields.product_type = false;
            }
        } else {
            requiredFields.product_type = false;
        }

        //for update - explain your changes validation
        if (updateProduct && isFieldEmpty(payload.explanation)) {
            requiredFields.reason_of_change = true;
            hasEmptyRequiredFields = true;
        } else {
            requiredFields.reason_of_change = false;
        }

        // Product Details
        if (isArrayEmpty(payload.product_details)) {
            validationErrors.push("Product details cannot be empty");
        } else {
            payload.product_details.forEach((product, index) => {
                // Size
                if (isArrayEmpty(product.size)) {
                    if (isFieldEmpty(product.new_size)) {
                        requiredFields.options[index] = true;
                        hasEmptyRequiredFields = true;
                    } else {
                        requiredFields.options[index] = false;
                    }
                } else {
                    requiredFields.options[index] = false;
                }

                // Online SRP
                if (isFieldEmpty(product.online_srp)) {
                    requiredFields.srp[index] = true;
                    hasEmptyRequiredFields = true;
                } else {
                    requiredFields.srp[index] = false;
                }

                // Distribution Costs
                product.distribution_cost.forEach((cost, distIndex) => {
                    // State
                    if (isArrayEmpty(cost.state)) {
                        requiredFields.state[index] = true;
                        hasEmptyRequiredFields = true;
                    } else {
                        requiredFields.state[index] = false;
                    }
                });

                product.distribution_cost.forEach((cost, distIndex) => {
                    // distributor
                    if (isArrayEmpty(cost.distributor)) {
                        if (isFieldEmpty(cost.new_distributor)) {
                            requiredFields.distributor[index] = true;
                            hasEmptyRequiredFields = true;
                        } else {
                            requiredFields.distributor[index] = false;
                        }
                    } else {
                        requiredFields.distributor[index] = false;
                    }
                });
            });
        }

        if (hasEmptyRequiredFields) {
            validationErrors.push("Required fields cannot be empty");
        } else {
            if (requiredFields.abv_limit_exceeded) {
                validationErrors.push("ABV cannot be greater than 79%");
            }
        }

        if (validationErrors.length > 0) {
            validationErrors.forEach(error => {
                addToast(error, { appearance: "error", autoDismiss: true });
            });
            return false;
        }

        setErrors({});
        return true;
    };

    const handleSubmit = () => {
        const defaultImage = "https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png";
        const variantErrors = variant_images.map(() => ({})); // An array to track errors for each variant image

        if (!validatePayload(payload)) {
            return;
        } else {
            // Monday Task: 6999628932_7012537690 Added Error message next to field
            let hasVariantErrors = false;

            for (let i = 0; i < variant_images.length; i++) {
                if (variant_images[i].title === null || variant_images[i].title === "" || variant_images[i].title.length === 0) {
                    variantErrors[i].title = "Variant image is missing title";
                    hasVariantErrors = true;
                } else if (variant_images[i].image === null || variant_images[i].image === undefined || variant_images[i].image === defaultImage) {
                    variantErrors[i].image = "Please Upload image";
                    hasVariantErrors = true;
                } else {
                    const isVariantPresent = payload.variant_size_for_images.some(variant => variant.value === variant_images[i].id);
                    if (!isVariantPresent) {
                        variantErrors[i].size = "Select a valid size for image";
                        hasVariantErrors = true;
                    }
                }
            }

            if (hasVariantErrors) {
                setErrors(prev => ({ ...prev, variant_images: variantErrors }));
                addToast("Required fields cannot be empty", { appearance: "error", autoDismiss: true });
                return;
            } else {
                setErrors(prev => ({ ...prev, variant_images: [] })); // Clear variant image errors if no errors are found
            }
            // Monday Task: 6999628932_7012537690 Added Error message next to field 
        }
        const processedPayload = preprocessPayload(payload);
        console.log("preprocessed data", processedPayload);

        const formData = new FormData();
        const payloadString = JSON.stringify(processedPayload);
        formData.append("payload", payloadString);

        const productImagesPayload = variant_images.map(img => ({
            size_id: img.id,
            size_title: img.title,
            // product_image_data: img.image,
            product_image_file: img.image.name
        }));
        const productImagesPayloadString = JSON.stringify(productImagesPayload);
        formData.append("product_images", productImagesPayloadString);

        // Append each image file to FormData
        variant_images.forEach((img, index) => {
            formData.append(`image_${index}`, img.image);
        });

        if (!updateProduct) {
            setSubmitBtnLoading(true);
            api.post("product/list/", formData)
                .then(response => {
                    if (response.status === 201) {
                        addToast("Product created successfully", { appearance: "success", autoDismiss: true });
                        history.push({ pathname: `/product-details`, search: new URLSearchParams({ productId: `${response?.data?.product_id}` }).toString() });
                    }
                })
                .catch(error => {
                    setSubmitBtnLoading(false); // Stop the loading state on error
                    if (error.response) {
                        if (error.response.status === 400) {
                            let message = error?.response?.data?.error;
                            addToast(message, { appearance: "error", autoDismiss: true });
                        } else if (error.response.status === 500) {
                            let message = error?.response?.data?.error || "Internal server error. Please try again.";
                            addToast(message, { appearance: "error", autoDismiss: true });
                        } else {
                            let message = error?.response?.data?.error || "An error occurred. Please try again.";
                            addToast(message, { appearance: "error", autoDismiss: true });
                        }
                        history.push("product");
                    } else {
                        // Handle network errors or other errors
                        console.log("error: ", error);
                        addToast("A network error occurred. Please check your connection and try again.", { appearance: "error", autoDismiss: true });
                    }
                });
        } else {
            setSubmitBtnLoading(true);
            api.put(`product/product-details/${productId}/`, formData)
                .then(response => {
                    if (response.status === 200) {
                        addToast("Product updated successfully", { appearance: "success", autoDismiss: true });
                        history.push({ pathname: `/product-details`, search: new URLSearchParams({ productId: `${response?.data?.product_id}` }).toString() });
                    }
                })
                .catch(error => {
                    setSubmitBtnLoading(false);  // Ensure loading stops on error
                    if (error.response && error.response.status === 400) {
                        let message = error?.response?.data?.error;
                        addToast(message, { appearance: "error", autoDismiss: true });
                        history.push("product");
                    } else if (error.response && error.response.status === 401) {
                        // Handle unauthorized case
                        addToast("Your session has expired. Please log in again and try again.", { appearance: "error", autoDismiss: true });
                        // Delay the redirection to the product page
                        setTimeout(() => {
                            history.push("product");
                        }, 4000);
                    } else {
                        // Handle other errors (network errors, etc.)
                        console.log("error: ", error);
                        addToast("A network error occurred. Please check your connection and try again.", { appearance: "error", autoDismiss: true });
                    }
                });
        }
    };

    useEffect(() => {
        fetchDataAndTransform("product/product-category-list/", category => ({ value: category.id, label: category.name }), setListing, "productCategoryList");

        fetchDataAndTransform("product/react/brands/", brand => ({ value: brand.value, label: brand.name }), setListing, "brandList");

        fetchDataAndTransform("product/product-types/", type => ({ value: type.id, label: type.name }), setListing, "productTypeList");

        fetchDataAndTransform("product/product-tags/", type => ({ value: type.id, label: type.name }), setListing, "tagsList");

        fetchDataAndTransform("product/product-variant-sizes/", type => ({ value: type.id, label: type.title }), setListing, "sizeList");

        fetchDataAndTransform("product/product-variant-distributors/", type => ({ value: type.id, label: type.name }), setListing, "distributorList");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (updateProduct && updatePayload) {
            setPayload(updatePayload);
        }
    }, [updateProduct, updatePayload]);

    return (
        <React.Fragment>
            <Container fluid style={{ paddingTop: "7rem" }}>
                <div className={styles.productConatiner}>
                    <h1>{updateProduct ? "Change Product" : "Create Product"}</h1>
                    <form>
                        <div style={{ marginBottom: "3rem" }}>
                            <SectionOne setPayload={setPayload} payload={payload} listing={listing} setListing={setListing} requiredFields={requiredFields} />
                        </div>
                        <div style={{ marginBottom: "3rem" }}>
                            {!updateProduct ? (
                                <SectionTwo
                                    setPayload={setPayload}
                                    payload={payload}
                                    listing={listing}
                                    setListing={setListing}
                                    requiredFields={requiredFields}
                                />
                            ) : (
                                <SectionTwoUpdate
                                    setPayload={setPayload}
                                    payload={payload}
                                    listing={listing}
                                    setListing={setListing}
                                    isUpdate={updateProduct}
                                    requiredFields={requiredFields}
                                />
                            )}
                        </div>
                        <div style={{ marginBottom: "1rem" }}>
                            <SectionThree setVariant_images={setVariant_images} payload={payload} listing={listing} setPayload={setPayload} errors={errors} />
                        </div>
                        {updateProduct ? (
                            <div style={{ marginBottom: "1rem" }}>
                                <ReasonForChange payload={payload} setPayload={setPayload} requiredFields={requiredFields} />
                            </div>
                        ) : null}
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <button className={styles.backBtn} onClick={() => history.push("/product")}>
                                ← Back
                            </button>
                            <button type="button" className={styles.submitBtn} onClick={handleSubmit} disabled={submitBtnLoading}>
                                Submit → {submitBtnLoading && <Spinner size="sm" color="light" />}
                            </button>
                        </div>
                    </form>
                </div>
            </Container>
        </React.Fragment>
    );
};

export default CreateProduct;
