import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap";
import { MDBCol, MDBIcon } from "mdbreact";
import { api } from "../../services/api";
import Joi from "joi";
import {
  CustomDropdown,
  CustomMultiSelectDropdown,
} from "../CustomDropdown/CustomDropdown";
import Select from 'react-select'

export function OverrideBillingRetailerModal({ url, toggle, isOpen, loading }) {
  const [isLoading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const product_variant_list = [];
  const { addToast } = useToasts();
  const [data, setData] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });
  const [paramsFilter, setParamsFilter] = useState({});
  const [inventory_location, setInventoryLocation] = useState("");
  const [inventoryLocations, setInventoryLocationlist] = useState([]);
  const [orderChannel, setOrderChannel] = useState();
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [selectedChannelDropdownValue, setselectedChannelDropdownValue] = useState([]);
  const [orderChannelList, setOrderChannelList] = useState([]);
  const [Retailer, setRetailer] = useState("");
  const [retailerId, setRetailerId] = useState("");
  const [retailerList, setRetailerList] = useState("");
  const [showModal, setShowModal] = useState(isOpen);
  const [error, setError] = useState({});
  const [amount, setAmount] = useState({
    MarketingFee: null,
    ShippingRate: null,
  });
  const [inventoryLocationIsValid, setInventoryLocationIsValid] =
    useState(true);
  const [stateIsValid, setStateIsValid] = useState(true);
  const [salesChannelIsValid, setSalesChannelIsValid] = useState(true);
  const [feeTypesList, setFeeTypesList] = useState([]);
  const [feeType, setFeeType] = useState();
  const [quantity, setQuantity] = useState({
    MinimumQuantity: null,
    MaximumQuantity: null,
  });

  //List of state hardcoded
  const stateListOptions = [
    { value: "__all__", label: "All States" },
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "DC", label: "District Of Columbia" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" },
  ];

  const arrayLocation = [{ value: "__all__", label: "All Locations" }];
  const inventoryLocationsList = arrayLocation.concat(inventoryLocations);
  const arrayChannels = [{ value: "__all__", label: "All Sales Channels" }];
  const orderChannelsList = arrayChannels.concat(orderChannelList);

  useEffect(() => {
    getOrderChannelList();
    getRetailerList();
    getFeeTypesList();
    api
      .get(url, {
        params: paramsFilter,
      })
      .then((response) => setData(response.data))
      .catch((error) => console.log(error));
  }, [paramsFilter]);

  let timer = null;

  const handleSearch = (e) => {
    // limpamos o timer
    clearTimeout(timer);
    // armazenamos o timer novamente
    timer = setTimeout(function () {
      setParamsFilter({
        ...paramsFilter,
        search: e.target.value,
      });
    }, 750);
  };

  const handleProductCheckbox = (e, product_id) => {
    if (selectedProduct.length <= 0) {
      let checkbox = document.querySelectorAll(".variant_" + product_id);
      for (let i = 0; i < checkbox.length; i++) {
        if (e.target.checked === true && checkbox[i].checked === false) {
          checkbox[i].checked = true;
        }
        if (e.target.checked === false && checkbox[i].checked === true) {
          checkbox[i].checked = false;
        }
        if (e.target.checked === false) {
          checkbox[i].checked = false;
        }
        if (
          e.target.checked === true &&
          product_variant_list.includes(parseInt(product_id)) === false
        ) {
          setSelectedProduct(product_variant_list);
        }
        for (let i = 0; i < checkbox.length; i++) {
          if (e.target.checked === true) {
            if (
              product_variant_list.includes(parseInt(checkbox[i].value)) ===
              false
            ) {
              product_variant_list.push(parseInt(checkbox[i].value));
              setSelectedProduct(product_variant_list);
            }
          }
        }
      }
    } else if (selectedProduct.length > 0) {
      if (e.target.checked === false) {
        let remove_checkbox = document.querySelectorAll(
          ".variant_" + product_id
        );
        for (let i = 0; i < remove_checkbox.length; i++) {
          remove_checkbox[i].checked = false;
          var removeVariantIdIndex = selectedProduct.indexOf(
            parseInt(remove_checkbox[i].value)
          );
          if (removeVariantIdIndex !== -1) {
            selectedProduct.splice(removeVariantIdIndex, 1);
            setSelectedProduct(selectedProduct);
          }
          setSelectedProduct(selectedProduct);
        }
      } else if (e.target.checked === true) {
        var new_product_variant_list = [];
        new_product_variant_list = selectedProduct;
        var productIdIndex = new_product_variant_list.indexOf(product_id);
        if (productIdIndex !== -1) {
          new_product_variant_list.splice(productIdIndex, 1);
        }
        let checkbox = document.querySelectorAll(".variant_" + product_id);
        for (let i = 0; i < checkbox.length; i++) {
          if (e.target.checked === true && checkbox[i].checked === false) {
            checkbox[i].checked = true;
          }
          if (e.target.checked === false && checkbox[i].checked === true) {
            checkbox[i].checked = false;
            var checkboxproductIdIndex = new_product_variant_list.indexOf(
              checkbox[i].value
            );
            if (productIdIndex !== -1) {
              new_product_variant_list.splice(checkboxproductIdIndex, 1);
            }
            setSelectedProduct(new_product_variant_list);
          }
          if (
            e.target.checked === true &&
            new_product_variant_list.includes(parseInt(product_id)) === false
          ) {
            setSelectedProduct(new_product_variant_list);
          }
          for (let i = 0; i < checkbox.length; i++) {
            if (e.target.checked === true) {
              if (
                new_product_variant_list.includes(
                  parseInt(checkbox[i].value)
                ) === false
              ) {
                new_product_variant_list.push(parseInt(checkbox[i].value));
                setSelectedProduct(new_product_variant_list);
              }
            }
          }
        }
      }
    }
  };

  const handleVariantProductCheckbox = (e, variant_id) => {
    var new_product_variant_list = [];
    if (selectedProduct.length > 0) {
      var myIndex = selectedProduct.indexOf(variant_id);
      if (myIndex !== -1) {
        selectedProduct.splice(myIndex, 1);
      } else {
        new_product_variant_list = selectedProduct;
        new_product_variant_list.push(parseInt(variant_id));
        setSelectedProduct(new_product_variant_list);
      }
    } else {
      new_product_variant_list = selectedProduct;
      new_product_variant_list.push(parseInt(variant_id));
      setSelectedProduct(new_product_variant_list);
    }
  };

  const getRetailerList = () => {
    api
      .get(`order/retailer-billing-overrides-retailer-list/`)
      .then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          setRetailerList(response.data);
        }
      })
      .catch((err) => {});
  };

  const getOrderChannelList = () => {
    api
      .get(`order/retailer-billing-overrides-channel-list/`)
      .then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          let salesChannel = response.data.map((item) => ({
            value: item.id,
            label: item.channel_store,
          }));
          setOrderChannelList(salesChannel);
        }
      })
      .catch((err) => {});
  };

  const getFeeTypesList = () => {
    api
      .get(`order/retailer-billing-overrides-fee-types/`)
      .then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          let feeTypeResponse = response.data.results.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setFeeTypesList(feeTypeResponse);
        }
      })
      .catch((err) => {});
  };

  const handleRetailerLocation = (e) => {
    setRetailer(e.target.value);
    setRetailerId(e.target.id);
    api
      .get(`retailer-inventory-location-list/`, {
        params: { id: e.target.id },
      })
      .then((response) => response)
      .then((response) => {
        if (response.status === 200) {
          let inventoryLocation = response.data.map((item) => ({
            value: item.name,
            label: item.name,
          }));
          setInventoryLocationlist(inventoryLocation);

          // Reset location selection to 'All Location'
          setInventoryLocation("__all__");
        }
      })
      .catch((err) => {});
  };

  //Validate input values
  const schema = Joi.object({
    MarketingFee: Joi.number().min(0).max(100).allow(null),
    ShippingRate: Joi.number().min(0).max(100).allow(null),
    MinimumQuantity: Joi.number().min(0).max(100).allow(null),
    MaximumQuantity: Joi.number().min(0).max(100).allow(null),
  });

  /**
   * perform error validation and set error message
   * @param {*} event input event
   */
  const handleSave = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    // const { name, value } = event.target;
    let errorData = { ...error };
    const errorMessage = validateProperty(event);
    if (errorMessage) {
      errorData[name] = errorMessage;
    } else {
      delete errorData[name];
    }
    let customerData = { ...amount };
    if (value === "") {
      value = null;
    }
    customerData[name] = value;
    setAmount(customerData);
    setError(errorData);
  };

  /**
   * perform error validation and set error message
   * @param {*} event input event
   */
    const handleQuantitySave = (event) => {
      let name = event.target.name;
      let value = event.target.value;
      // const { name, value } = event.target;
      let errorData = { ...error };
      const errorMessage = validateProperty(event);
      if (errorMessage) {
        errorData[name] = errorMessage;
      } else {
        delete errorData[name];
      }
      let customerQuantityData = { ...quantity };
      if (value === "") {
        value = null;
      }
      customerQuantityData[name] = value;
      setQuantity(customerQuantityData);
      setError(errorData);
    };

  /**
   * validate input event and return the error
   * @param {*} event input event
   * @returns
   */
  const validateProperty = (event) => {
    const { name, value } = event.target;
    const obj = { [name]: value };
    const subSchema = { [name]: schema[name] };
    const result = schema.validate(obj, subSchema);
    const { error } = result;
    return error ? error.details[0].message : null;
  };

  const handleNext = () => {
    if (data.next) {
      let results = data.results;
      setLoading(true);
      api
        .get(data.next)
        .then((response) => {
          const new_results = results.concat(response.data.results);
          setData({
            ...data,
            results: new_results,
            next: response.data.next,
            previous: response.data.previous,
          });
          setLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const result = schema.validate(amount, { abortEarly: true });
    const { error } = result;
    inventory_location.length !== 0
      ? setInventoryLocationIsValid(true)
      : setInventoryLocationIsValid(false);
    selectedState.length !== 0 ? setStateIsValid(true) : setStateIsValid(false);
    selectedChannel.length !== 0
      ? setSalesChannelIsValid(true)
      : setSalesChannelIsValid(false);
    if (!error) {
      if (
        retailerId.length !== 0 &&
        inventory_location.length !== 0 &&
        selectedState.length !== 0 &&
        selectedChannel.length !== 0 &&
        selectedProduct.length !== 0
      ) {
        document.getElementById("save_btn").disabled = true;
        let states = selectedState.some((item) => item.value == "__all__")
          ? "__all__"
          : selectedState.map((item) => item.value);
        setLoading(true);
        api
          .post("order/retailer-billing-overrides/", {
            inventory_location: inventory_location,
            ship_to_state: states,
            variant: selectedProduct,
            marketing_fee: amount.MarketingFee,
            shipping_rate: amount.ShippingRate,
            sales_channel: selectedChannel,
            retailer: retailerId,
            fee_type: feeType,
            shipping_as_is: payoutsShipping,
            minimum_quantity: quantity.MinimumQuantity,
            maximum_quantity: quantity.MaximumQuantity,
          })
          .then((response) => {
            if (response.status === 201) {
              addToast(response.data.message, {
                appearance: "success",
                autoDismiss: true,
                autoDismissTimeout: 40000,
              });
              setLoading(false);
              toggle();
              setTimeout(() => {
                window.location.reload(false);
              }, 1000);
            } else if (
              response.data.success === false &&
              response.data.status === 400
            ) {
              var errors_list = response.data.errors_list;
              errors_list.forEach((element) => {
                if (
                  element.variant_id &&
                  element.state &&
                  element.inventory_location
                ) {
                  addToast(
                    "VARIANT :" +
                      element.variant_id +
                      ", STATE: " +
                      element.state +
                      ", INVENTORY LOCATION: " +
                      element.inventory_location +
                      " Already exist !",
                    {
                      appearance: "error",
                      autoDismiss: true,
                    }
                  );
                } else {
                  addToast("Created Successfully", {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  setLoading(false);
                  setTimeout(() => {
                    window.location.reload(false);
                  }, 1000);
                }
              });
              toggle();
            } else {
              addToast(response.data.message, {
                appearance: "error",
                autoDismiss: true,
              });
              toggle();
            }
          })
          .catch((err) => {
            addToast("Something went wrong, please try again !", {
              appearance: "error",
              autoDismiss: true,
            });
            setLoading(false);
            toggle();
          });
      } else {
        addToast("Missing required field !", {
          appearance: "error",
          autoDismiss: true,
        });
        setLoading(false);
      }
    } else {
      const errorData = {};
      for (let item of error.details) {
        const name = item.path[0];
        const message = item.message;
        errorData[name] = message;
      }
      setError(errorData);
      setLoading(false);
      return errorData;
    }
  };

  useEffect(() => {
    setShowModal(isOpen);
    if (isOpen) {
      // Clear form data if set last time
      setRetailer("");
      setParamsFilter("");
      setInventoryLocationlist("");
      setSelectedState("");
      setOrderChannel("");
      setSelectedChannel("");
      setFeeType("");
      setAmount({
        MarketingFee: null,
        ShippingRate: null,
      });
      setError("");
    }
  }, [isOpen]);

  const [payoutsShipping, setPayoutsShipping] = useState(false);
  const [shippingInput, setShippingInput] = useState(false);

  const handlePayoutsShippingCheckbox = (e) => {
    if (e.target.checked) {
      setPayoutsShipping(true);
      setShippingInput(true);
      setAmount({MarketingFee: amount.MarketingFee, ShippingRate: null});
    } else {
      setPayoutsShipping(false);
      setShippingInput(false);
    };
  }

  const handleChannelChange = (selected) => {
    console.log("selected", selected)
    const selectedChannelIds = selected.map((record) => record.value);
    console.log("selectedChannelIds", selectedChannelIds)
    setselectedChannelDropdownValue(selected);
    var new_channel_list = [];
    selected.forEach((event) => {
      if (event.value === "__all__") {
        setSelectedChannel("__all__");
      } else {
        new_channel_list.push(event.value);
        setSelectedChannel(selectedChannelIds);
      }
    });
  };

  useEffect(() => {
    //console.log(Retailer)
  }, [Retailer])

  return (
    <>
      {showModal && (
        <div className="form-group mb-3 d-flex flex-column" data-field="name">
          <Modal
            style={{ marginTop: "100px" }}
            isOpen={showModal}
            onExit={toggle}
            toggle={() => toggle()}
            size={"lg"}
          >
            <Form onSubmit={handleSubmitForm}>
              <ModalHeader toggle={toggle} className="bg-primary text-white">
                <span className="text-white h3">
                  Retailer Payout Overrides
                </span>
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Row>
                    <Col>
                      <Label>Retailer  *</Label>
                      <br />
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        value={
                          Retailer
                            ? { value: Retailer.id, label: Retailer.username }
                            : null
                        }
                        options={retailerList.map(( item ) => ({
                          value: item,
                          label: item.username,
                        }))}
                        onChange={(selectedOption) => {
                          setRetailer(selectedOption ? selectedOption.value : "")

                          setRetailerId(selectedOption.value.id)
                        }

                        }
                        placeholder="Select Retailer"
                        isClearable
                      />

                    </Col>
                    <Col>
                      <Label>Inventory Location *</Label>
                      <br />
                      <CustomDropdown
                        onChange={setInventoryLocation}
                        options={inventoryLocationsList}
                        placeholder="Select location"
                        isValid={inventoryLocationIsValid}
                        value={inventory_location}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <Label>State *</Label>
                      <CustomMultiSelectDropdown
                        onChangeHandler={setSelectedState}
                        options={stateListOptions}
                        placeholder="Select one or multiple state(s)"
                        isValid={stateIsValid}
                        values={selectedState}
                      />
                    </Col>
                    <Col>
                      <Label>Sales Channel *</Label>
                      <br />
                      <CustomMultiSelectDropdown
                        onChangeHandler={handleChannelChange}
                        options={orderChannelsList}
                        placeholder="Select one or multiple sales channel"
                        isValid={salesChannelIsValid}
                        values={selectedChannelDropdownValue}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <Label>Marketing Fee</Label>
                      <Input
                        id="marketingFee"
                        name="MarketingFee"
                        placeholder="Enter the marketing fee in %"
                        type="number"
                        value={amount.MarketingFee || ""}
                        onChange={handleSave}
                        onWheel={event => { event.target.blur(); }}
                      />
                      {error.MarketingFee && (
                        <span
                          className="error"
                          style={{ color: "red", float: "left", width: "auto" }}
                        >
                          {" "}
                          {error.MarketingFee}{" "}
                        </span>
                      )}
                    </Col>
                    <Col>
                      <Label>Marketing Fee Type</Label>
                      <CustomDropdown
                        onChange={setFeeType}
                        options={feeTypesList}
                        isValid={true}
                        placeholder="Marketing Fee Type"
                      />
                    </Col>
                    <Col>
                      <Label>Shipping Rate</Label>
                      <Input
                        id="shippingRate"
                        name="ShippingRate"
                        placeholder="Enter the shipping rate in $"
                        type="number"
                        value={amount.ShippingRate || ""}
                        onChange={handleSave}
                        disabled={shippingInput}
                        onWheel={event => { event.target.blur(); }}
                      />
                      {error.ShippingRate && (
                        <span
                          className="error"
                          style={{ color: "red", float: "left", width: "auto" }}
                        >
                          {" "}
                          {error.ShippingRate}{" "}
                        </span>
                      )}
                      <div>
                      <input
                        type="checkbox"
                        name={"check_shipping"}
                        id={"checkbox_shipping"}
                        onChange={handlePayoutsShippingCheckbox}
                      />&nbsp;
                      <span>Order's Shipping Value</span>
                    </div>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <Label>Minimum Quantity</Label>
                      <Input
                        id="minimumQuantity"
                        name="MinimumQuantity"
                        placeholder="Enter the minimum quantity"
                        type="number"
                        value={quantity.MinimumQuantity || ""}
                        onChange={handleQuantitySave}
                        onWheel={event => { event.target.blur(); }}
                      />
                      {error.MinimumQuantity && (
                        <span
                          className="error"
                          style={{ color: "red", float: "left", width: "auto" }}
                        >
                          {" "}
                          {error.MinimumQuantity}{" "}
                        </span>
                      )}
                    </Col>
                    <Col>
                      <Label>Maximum Quantity</Label>
                      <Input
                        id="maximumQuantity"
                        name="MaximumQuantity"
                        placeholder="Enter the maximum quantity"
                        type="number"
                        value={quantity.MaximumQuantity || ""}
                        onChange={handleQuantitySave}
                        onWheel={event => { event.target.blur(); }}
                      />
                      {error.MaximumQuantity && (
                        <span
                          className="error"
                          style={{ color: "red", float: "left", width: "auto" }}
                        >
                          {" "}
                          {error.MaximumQuantity}{" "}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Label className="mt-3">Variant *</Label>
                  <MDBCol style={{ display: "-webkit-box" }}>
                    <MDBIcon icon="search" className="mr-2 ml-0" />
                    <input
                      className="form-control form-control-sm"
                      onKeyDown={handleSearch}
                      type="text"
                      placeholder="Search"
                      aria-label="Search"
                    />
                  </MDBCol>
                  <ul
                    className={"p-2 overflow-auto"}
                    style={{ maxHeight: "300px" }}
                    id={"filter_"}
                  >
                    {data.results.map((product) => (
                      <div key={product.id + "_prod_"}>
                        <div
                          key={product.id + "_product_"}
                          className="row my-2 d-flex align-items-center"
                          style={{ width: "40rem" }}
                        >
                          <div className="col-2 pr-0">
                            <Label className={"text-sm"}>
                              <input
                                type="checkbox"
                                id={"_" + product.id}
                                className={"checkbox_product"}
                                value={product.id}
                                data-label={product.name}
                                data-image={product.image}
                                defaultChecked={product.checked}
                                onChange={(e) =>
                                  handleProductCheckbox(e, product.id)
                                }
                                style={{ marginRight: "20px" }}
                              />
                            </Label>
                            {product.image ? (
                              <img
                                src={product.image}
                                className="img-thumbnail rounded-0"
                                width="50%"
                              />
                            ) : (
                              <img
                                src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                                className="img-thumbnail rounded-0"
                                width="50%"
                              />
                            )}
                          </div>
                          <div className="col-10">
                            <a
                              href={
                                window.env.APP_URL + "product/" + product.id
                              }
                              target="_blank"
                            >
                              <span className="text-wrap"> {product.name}</span>
                              <span
                                className="text-right"
                                style={{ paddingLeft: "10px", color: "black" }}
                              ></span>
                            </a>
                          </div>
                        </div>
                        {product.variants &&
                          product.variants.map((variant, index) => (
                            <div
                              className={"row sub-product ml-3"}
                              key={index + "_variant_"}
                            >
                              <div
                                className="col-2 pr-0 variant-container"
                                key={index}
                              >
                                <Label className={"text-sm"}>
                                  <input
                                    type="checkbox"
                                    id={"_" + variant.id}
                                    className={"variant_" + product.id}
                                    value={variant.id}
                                    data-label={variant.sku}
                                    data-image={variant.image}
                                    defaultChecked={variant.checked}
                                    onChange={(e) =>
                                      handleVariantProductCheckbox(
                                        e,
                                        variant.id
                                      )
                                    }
                                    style={{ marginRight: "20px" }}
                                  />
                                </Label>
                                {variant.image ? (
                                  <img
                                    src={variant.image}
                                    className="img-thumbnail rounded-0"
                                    width="50%"
                                  />
                                ) : (
                                  <img
                                    src="https://assets-barcart.s3.amazonaws.com/static/images/placeholder-bottle-and-glass.png"
                                    className="img-thumbnail rounded-0"
                                    width="50%"
                                  />
                                )}
                              </div>
                              <a href={void 0}>
                                {variant?.size_title === null ?
                                  <span className="text-wrap">{`${variant.sku}`}</span>
                                  :
                                  <span className="text-wrap">{`${variant.size_title} - ${variant.sku}`}</span>
                                }
                                <span
                                  className="text-right"
                                  style={{ color: "black" }}
                                ></span>
                              </a>
                            </div>
                          ))}
                      </div>
                    ))}

                    {data.results.length < data.count && (
                      <li className={"text-center"}>
                        {!isLoading ? (
                          <Button
                            className={"btn btn-sm "}
                            onClick={handleNext}
                          >
                            show more
                          </Button>
                        ) : (
                          <span className={"text-sm text-muted"}>
                            Loading...
                          </span>
                        )}
                      </li>
                    )}
                  </ul>
                </FormGroup>
              </ModalBody>
              <ModalFooter className="mt-0 pt-0">
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  id="save_btn"
                  disabled={isLoading}
                  onClick={handleSubmitForm}
                >
                  Save
                  {isLoading && <Spinner size="sm" color="light" />}
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </div>
      )}
    </>
  );
}
