/*
    Monday Task ID - 7641942845
    Migration of Bulk Order Details(Django Page) -> React
    Author - Akshay Vishwakarma
*/

import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { api } from "services/api";
import { useToasts } from "react-toast-notifications";
import { stateListOptions } from "utils/constants";

export const CreateBulkItem = ({ updateBulkItem = false, modalIsOpen, setModalIsOpen, payload, setPayload, setUpdateBulkItem, refreshPage }) => {
    const { addToast } = useToasts();
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const toggleModel = () => {
        setModalIsOpen(!modalIsOpen);
        setErrors({})
        setSubmitBtnLoading(false)
        if (updateBulkItem) {
            setUpdateBulkItem(false)
        }
    };

    const handleChange = (field, value) => {
        setPayload(
            prePayload => ({
                ...prePayload,
                [field]: field === "line_item_quantity" ? Number(value) : value
            })
        )
    }

    const validatePayload = () => {
        const newErrors = {};
        const requiredFields = ["line_item_sku", "line_item_quantity", "first_name", "last_name", "customer_email", "shipping_address_1", "shipping_city", "shipping_state", "shipping_zip"];

        // Check for empty required fields
        requiredFields.forEach((field) => {
            const value = payload[field];
            if (value === undefined || value === null ||  (typeof value === 'string' && value.trim() === "") || (typeof value !== 'string' && !value)) {
                newErrors[field] = "This field is required.";
            }
        });

        // Check for numeric fields
        if (isNaN(payload.line_item_quantity) || payload.line_item_quantity <= 0) {
            newErrors.line_item_quantity = "Quantity must be a valid number.";
        }

        // Check if zip is numeric and not more than 5 digits
        if (!/^\d{1,5}$/.test(payload.shipping_zip)) {
            newErrors.shipping_zip = "Zip must be a number with a maximum of 5 digits.";
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(!emailRegex.test(payload.customer_email)){
            newErrors.customer_email = "Enter a valid email.";
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const submit = () => {
        if (!updateBulkItem) {
            setSubmitBtnLoading(true)
            if (validatePayload()) {
                api.post("bulk-orders-item-create/", payload)
                    .then(response => {
                        if (response.status === 201) {
                            addToast("Bulk Order Item created successfully!", { appearance: "success", autoDismiss: true });
                            setSubmitBtnLoading(false)
                            setModalIsOpen(!modalIsOpen)
                            refreshPage()
                        }
                    })
                    .catch(error => {
                        setSubmitBtnLoading(false)
                        if (error.response && error.response.status === 400) {
                            let message = error?.response?.data?.error;
                            addToast(message, { appearance: "error", autoDismiss: true });
                            setModalIsOpen(!modalIsOpen)

                        } else {
                            let message = error?.response?.data?.error;
                            addToast(message, { appearance: "error", autoDismiss: true });
                            setModalIsOpen(!modalIsOpen)
                        }
                    });
            }else{
                setSubmitBtnLoading(false)
            }
        } else {
            setSubmitBtnLoading(true)
            if (validatePayload()) {
                api.put(`bulk-orders-item-update/${payload?.id}/`, payload)
                    .then(response => {
                        if (response.status === 200) {
                            addToast("Bulk Order Item updated successfully!", { appearance: "success", autoDismiss: true });
                            setSubmitBtnLoading(false)
                            setModalIsOpen(!modalIsOpen)
                            refreshPage()
                        }
                    })
                    .catch(error => {
                        setSubmitBtnLoading(false)
                        if (error.response && error.response.status === 400) {
                            let message = error?.response?.data?.error;
                            addToast(message, { appearance: "error", autoDismiss: true });
                            setModalIsOpen(!modalIsOpen)

                        } else {
                            let message = error?.response?.data?.error;
                            addToast(message, { appearance: "error", autoDismiss: true });
                            setModalIsOpen(!modalIsOpen)
                        }
                    });
            }else{
                setSubmitBtnLoading(false)
            }
        }
    }


    return (
        <Modal isOpen={modalIsOpen} toggle={toggleModel} size="lg">
            <ModalHeader className="bg-primary text-white">
                <span className="text-white h3">Edit Order</span>
            </ModalHeader>
            <ModalBody>
                <div className="container">
                    <div className="row mb-4">
                        <div className="col">
                            <label >Line Item Sku <sup>*</sup></label>
                            <input type="text" className="form-control" id="line-item-sku" placeholder="Line Item Sku" value={payload?.line_item_sku} onChange={(e) => handleChange("line_item_sku", e.target.value)} required />
                            {errors.line_item_sku && <span className="text-danger">{errors.line_item_sku}</span>}
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-sm-6 mb-4 mb-sm-0">
                            <label >Line Item Name</label>
                            <input type="text" className="form-control" id="line-item-name" placeholder="Line Item Name" value={payload?.line_item_name} onChange={(e) => handleChange("line_item_name", e.target.value)} />
                        </div>
                        <div className="col-12 col-sm-6">
                            <label >Line Item Quantity <sup>*</sup></label>
                            <input type="number" className="form-control" id="line-item-quantity" placeholder="Line Item Qunatity" value={payload?.line_item_quantity} onChange={(e) => handleChange("line_item_quantity", e.target.value)} required />
                            {errors.line_item_quantity && <span className="text-danger">{errors.line_item_quantity}</span>}
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-sm-6 mb-4 mb-sm-0">
                            <label >First Name <sup>*</sup></label>
                            <input type="text" className="form-control" id="first-name" placeholder="First Name" value={payload?.first_name} onChange={(e) => handleChange("first_name", e.target.value)} required />
                            {errors.first_name && <span className="text-danger">{errors.first_name}</span>}
                        </div>
                        <div className="col-12 col-sm-6">
                            <label >Last Name <sup>*</sup></label>
                            <input type="text" className="form-control" id="last-name" placeholder="Last name" value={payload?.last_name} onChange={(e) => handleChange("last_name", e.target.value)} required />
                            {errors.last_name && <span className="text-danger">{errors.last_name}</span>}
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-sm-6 mb-4 mb-sm-0">
                            <label >Customer Email <sup>*</sup></label>
                            <input type="email" className="form-control" id="customer-email" placeholder="Customer Email" value={payload?.customer_email} onChange={(e) => handleChange("customer_email", e.target.value)} required />
                            {errors.customer_email && <span className="text-danger">{errors.customer_email}</span>}
                        </div>
                        <div className="col-12 col-sm-6">
                            <label >Shipping Phone</label>
                            <input type="text" className="form-control" id="shipping-pohone" placeholder="Shipping Phone" value={payload?.shipping_phone} onChange={(e) => handleChange("shipping_phone", e.target.value)} />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col">
                            <label >Shipping Company</label>
                            <input type="text" className="form-control" id="shipping-company" placeholder="Shipping Company" value={payload?.shipping_company} onChange={(e) => handleChange("shipping_company", e.target.value)} />
                        </div>
                    </div>
                    <div className="row mb-4 mb-4 ">
                        <div className= "col-12 col-sm-6 mb-4 mb-sm-0">
                            <label >Shipping Address1 <sup>*</sup></label>
                            <input type="text" className="form-control" id="shipping-address1" placeholder="Shipping Address 1" value={payload?.shipping_address_1} onChange={(e) => handleChange("shipping_address_1", e.target.value)} required />
                            {errors.shipping_address_1 && <span className="text-danger">{errors.shipping_address_1}</span>}
                        </div>
                        <div className="col-12 col-sm-6">
                            <label >Shipping Address2</label>
                            <input type="text" className="form-control" id="shipping-address2" placeholder="Shipping Address 2" value={payload?.shipping_address_2} onChange={(e) => handleChange("shipping_address_2", e.target.value)} />
                        </div>
                    </div>
                    <div className="row mb-4 mb-4 ">
                        <div className="col-12 col-sm-6 mb-4 mb-sm-0">
                            <label >Shipping City <sup>*</sup></label>
                            <input type="text" className="form-control" id="shipping-city" placeholder="Shipping City" value={payload?.shipping_city} onChange={(e) => handleChange("shipping_city", e.target.value)} required />
                            {errors.shipping_city && <span className="text-danger">{errors.shipping_city}</span>}
                        </div>
                        <div className="col-12 col-sm-6">
                            <label >Shipping State <sup>*</sup></label>
                            <select className="custom-select" id="shipping-state" value={payload?.shipping_state} onChange={(e) => handleChange("shipping_state", e.target.value)} required>
                                {
                                    stateListOptions.slice(2)?.map(state => (
                                        <option value={state?.value} key={state?.value}>{state.label}</option>
                                    ))
                                }

                            </select>
                            {errors.shipping_state && <span className="text-danger">{errors.shipping_state}</span>}
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-sm-6 mb-4 mb-sm-0">
                            <label >Shipping Zip <sup>*</sup></label>
                            <input type="text" className="form-control" id="shipping-zip" placeholder="Shipping Zip" value={payload?.shipping_zip} onChange={(e) => handleChange("shipping_zip", e.target.value)} required />
                            {errors.shipping_zip && <span className="text-danger">{errors.shipping_zip}</span>}
                        </div>
                        <div className="col-12 col-sm-6 d-flex align-items-center">
                            <input type="checkbox" id="gift-wrapping" checked={payload?.gift_wrapping} onChange={(e) => handleChange("gift_wrapping", e.target.checked)} />
                            <label htmlFor="gift-wrapping" className="ml-2">Gift Wrapping</label>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggleModel}>Close</Button>
                <Button
                    className="bg-primary text-white"
                    type="button"
                    onClick={() => submit()} disabled={submitBtnLoading}>
                    Save Changes {submitBtnLoading && <Spinner size="sm" color="light" />}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
