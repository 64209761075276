// 6322422002- Store Details Migration
import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'

import { api } from '../../services/api'



const SubscriptionDetail = () => {

    const searchParams = new URLSearchParams(window.location.search);
    const [subscription, setSubscription] = useState({})

    const getData = () => {


        const subscriptionId = searchParams.get("subscription_id");
        api.get(`subscription/${subscriptionId}`, {})
            .then(response => {
                //main state
              const data = response.data
              console.log(data)
              setSubscription(data)
            })
            .catch(err => {

            });
    };

    useEffect(()=>{
      console.log("tests")
        getData();
    }, [])

    return (
      <>
            <Container className={"py-7"}>
              <Row>
                <Col>
                  <h3 className="mb-0 text">Subscription Detail</h3>
                </Col>
                <Col className="text-right">
                  <Button
                    color="primary"
                    outline
                    size="sm"
                    tag={"a"}
                    href={`/subscription`}
                  >
                    All Subscriptions
                  </Button>
                  <Button
                    color="primary"
                    outline
                    size="sm"
                    tag={'a'}
                    href={`https://dashboard.stripe.com/subscriptions/${subscription.id}`}>
                    See in Stripe
                  </Button>
                </Col>
              </Row>


              <Card className="shadow mt-3" style={{}}>
                <CardBody className="border-0">
                  <Container>
                    <Row>
                      <Col md={4}>
                        <label htmlFor="">Owner</label>
                      </Col>
                      <Col md={4}>
                        <label htmlFor="">Subscription ID</label>
                      </Col>
                      <Col md={4}>
                        Status
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <small>{subscription.owner}</small>
                      </Col>
                      <Col md={4}>
                        <small>{subscription.subscription_id}</small>
                      </Col>
                      <Col md={4}>
                       <small> <Badge
                          color="success"
                          size="xs"
                          pill
                        >
                         {subscription.status}
                        </Badge></small>
                      </Col>
                    </Row>
                  </Container>
                </CardBody>

              </Card>

              {subscription?.subscription_items?.map(item => (
                <Card className={"mt-4"} key={item.id}>
                  <CardHeader>
                    {item.name}({item.brands.length})
                  </CardHeader>
                  <CardBody>
                    {item.brands.map(brand => (
                      <small> <Badge
                        color="primary"
                        size="xs"
                        pill
                      >
                        {brand}
                      </Badge></small>
                    ))}
                  </CardBody>
                </Card>))}
            </Container>
      </>

    );
};
export default SubscriptionDetail;
