//**7057092675 - Standard Rate Listing */
import React, { useContext } from "react";
import { Container } from "reactstrap";
import { AuthContext } from "contexts/AuthContext";
import StandardRateTable from "./StandardRateTable";

const StoreStandardRateListing = () => {
    const { isAuthentication } = useContext(AuthContext);

    return (
        <>
            {isAuthentication ? (
                <Container fluid>
                    <div className={"pt-7"}>
                        <StandardRateTable />
                    </div>
                </Container>
            ) : null}
        </>
    );
};

export default StoreStandardRateListing;
