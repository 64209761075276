/*
    Monday Task ID - 7641942845
    Migration of Bulk Order Details(Django Page) -> React
    Author - Akshay Vishwakarma
*/

import React, { useState } from "react";
import { Container, Tooltip, Spinner } from "reactstrap";
import { CreateBulkItem } from "./CreateBulkItem";
import DeleteBulkItemModel from "./DeleteBulkItemModal";
import { useHistory } from "react-router-dom";
import { api } from "services/api";
import { useToasts } from "react-toast-notifications";

const BulkDetails = ({ bulkOrderDetails, refreshPage }) => {
    const history = useHistory();
    const { addToast } = useToasts();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [bulkItemId, setBulkItemId] = useState(null);
    const [label, setLabel] = useState(null);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [updateBulkItem, setUpdateBulkItem] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [generatingInvoice, setGeneratingInvoice] = useState(false);
    const [tooltipSuccess, setTooltipSuccess] = useState({});
    const [tooltipReview, setTooltipReview] = useState({});

    const toggle = () => setTooltipOpen(!tooltipOpen);
    const toggleSuccess = id => {
        setTooltipSuccess(prev => ({ ...prev, [id]: !prev[id] }));
    };
    const toggleReview = id => {
        setTooltipReview(prev => ({ ...prev, [id]: !prev[id] }));
    };
    const [payload, setPayload] = useState({
        line_item_sku: "",
        line_item_name: "",
        line_item_quantity: 0,
        first_name: "",
        last_name: "",
        customer_email: "",
        shipping_phone: "",
        shipping_company: "",
        shipping_address_1: "",
        shipping_address_2: "",
        shipping_city: "",
        shipping_state: "AL",
        shipping_zip: "",
        gift_wrapping: false,
        bulk_order_id: ""
    });

    const createBulkItem = order_id => {
        setPayload({
            line_item_sku: "",
            line_item_name: "",
            line_item_quantity: 0,
            first_name: "",
            last_name: "",
            customer_email: "",
            shipping_phone: "",
            shipping_company: "",
            shipping_address_1: "",
            shipping_address_2: "",
            shipping_city: "",
            shipping_state: "AL",
            shipping_zip: "",
            gift_wrapping: false,
            bulk_order_id: order_id
        });
        setModalIsOpen(true);
    };

    const editBulkOrder = bulkItem => {
        setPayload({
            id: bulkItem?.id,
            line_item_sku: bulkItem?.line_item_sku,
            line_item_name: bulkItem?.line_item_name,
            line_item_quantity: bulkItem?.line_item_quantity,
            first_name: bulkItem?.first_name,
            last_name: bulkItem?.last_name,
            customer_email: bulkItem?.customer_email,
            shipping_phone: bulkItem?.shipping_phone,
            shipping_company: bulkItem?.shipping_company,
            shipping_address_1: bulkItem?.shipping_address_1,
            shipping_address_2: bulkItem?.shipping_address_2,
            shipping_city: bulkItem?.shipping_city,
            shipping_state: bulkItem?.shipping_state?.code,
            shipping_zip: bulkItem?.shipping_zip,
            gift_wrapping: bulkItem?.gift_wrapping,
            bulk_order_id: bulkItem?.bulk
        });
        setUpdateBulkItem(true);
        setModalIsOpen(!modalIsOpen);
    };

    const handleDeleteBulkItem = (id, first_name, last_name) => {
        setLabel(`${first_name} ${last_name}`);
        setBulkItemId(id);
        setDeleteModalIsOpen(true);
    };

    const generateInvoice = bulk_order_id => {
        setGeneratingInvoice(true);
        api.get(`draft-order/create/${bulk_order_id}/`)
            .then(response => {
                addToast(response?.data?.message, { appearance: "success", autoDismiss: true });
                refreshPage();
                setGeneratingInvoice(false);
            })
            .catch(error => {
                setGeneratingInvoice(false);
                let message = error?.response?.data?.error;
                addToast(message, { appearance: "error", autoDismiss: true });
                refreshPage();
            });
    };

    return (
        <React.Fragment>
            <CreateBulkItem
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                updateBulkItem={updateBulkItem}
                payload={payload}
                setPayload={setPayload}
                setUpdateBulkItem={setUpdateBulkItem}
                refreshPage={refreshPage}
            />
            <DeleteBulkItemModel
                DeleteModalIsOpen={deleteModalIsOpen}
                setDeleteModalIsOpen={setDeleteModalIsOpen}
                id={bulkItemId}
                name={label}
                refreshPage={refreshPage}
            />
            <Container fluid style={{ paddingTop: "7rem" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <h2> Bulk Order Details</h2>
                    </div>
                    <div>
                        <button className="btn btn-outline-primary" onClick={() => history.push("/bulk-orders")}>
                            All Bulk Orders
                        </button>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <dl className="row">
                            <dt className="col-lg-3 py-2">Title</dt>
                            <dd className="col-lg-9 py-2" style={{ backgroundColor: "#fafafc" }}>
                                {bulkOrderDetails?.title}
                            </dd>

                            <dt className="col-lg-3 py-2">Email</dt>
                            <dd className="col-lg-9 py-2" style={{ backgroundColor: "#fafafc" }}>
                                {bulkOrderDetails?.email}
                            </dd>

                            {bulkOrderDetails?.order && bulkOrderDetails?.react_tracking_url ? (
                                <>
                                    <dt className="col-lg-3 py-2">Main Order</dt>
                                    <dd className="col-lg-9 py-2" style={{ backgroundColor: "#fafafc" }}>
                                        <a href={`${bulkOrderDetails?.react_tracking_url}`} target="_blank" rel="noreferrer">
                                            {bulkOrderDetails?.order_name}
                                        </a>
                                    </dd>
                                </>
                            ) : null}

                            {bulkOrderDetails?.show_file ? (
                                <>
                                    <dt className="col-lg-3 py-2">File</dt>
                                    <dd className="col-lg-9 py-2" style={{ backgroundColor: "#fafafc" }}>
                                        <a href={`${bulkOrderDetails?.file_url}`}>{bulkOrderDetails?.file_name}</a>
                                    </dd>
                                </>
                            ) : null}
                        </dl>
                    </div>
                </div>

                <div className="col-12 mt-4 px-0">
                    <div className="card table-responsive">
                        <table className="table table-hover table-flush">
                            <thead className="thead-light">
                                <tr>
                                    <th>SKU</th>
                                    <th>Product Name</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Total Price</th>
                                    <th>Gift Wrapping</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Consumer Email</th>
                                    <th>Shipping Company</th>
                                    <th>Shipping Phone</th>
                                    <th>Shipping Address 1</th>
                                    <th>Shipping Address 2</th>
                                    <th>Shipping City</th>
                                    <th>Shipping State</th>
                                    <th>Shipping Zip</th>
                                    <th className="position-sticky right-0 bg-light">
                                        <div className="row" style={{ width: "15rem" }}>
                                            {bulkOrderDetails?.workflow === "review" || bulkOrderDetails?.workflow === "error" ? (
                                                <div className="col text-left m-0 p-0">
                                                    <span className="p-1 btn btn-neutral btn-sm px-2" onClick={() => createBulkItem(bulkOrderDetails?.id)}>
                                                        <i className="fas fa-plus-circle"></i>
                                                        New Item
                                                    </span>
                                                </div>
                                            ) : null}
                                            <div className="col text-right pt-1">
                                                <strong className="h5 text-right">ITEMS ({bulkOrderDetails?.bulk_order_items?.length})</strong>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {bulkOrderDetails &&
                                    bulkOrderDetails?.bulk_order_items?.map(bulkItem =>
                                        bulkItem?.has_error ? (
                                            <tr className="text-danger" key={bulkItem?.id}>
                                                <td>{bulkItem?.line_item_sku ?? ""}</td>
                                                <td colSpan="15">
                                                    <span
                                                        onClick={() => {
                                                            editBulkOrder(bulkItem);
                                                        }}
                                                        style={{ cursor: "pointer" }}>
                                                        <div className="alert alert-danger m-0 py-1 px-3">{bulkItem?.error ?? "None"}</div>
                                                    </span>
                                                </td>
                                                <td className="position-sticky right-0 bg-lighter">
                                                    <div className="row border-left border-light align-items-center" style={{ width: "15rem", zIndex: "9999" }}>
                                                        <div className="col pl-1">
                                                            <span style={{ display: "flex", gap: "2rem" }}>
                                                                <span
                                                                    onClick={() => {
                                                                        editBulkOrder(bulkItem);
                                                                    }}
                                                                    style={{ cursor: "pointer" }}>
                                                                    <i className="fas fa-edit fa-fw text-default"></i>
                                                                </span>
                                                                <span
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        handleDeleteBulkItem(bulkItem?.id, bulkItem?.first_name, bulkItem?.last_name);
                                                                    }}>
                                                                    <i className="fas fa-trash-alt fa-fw text-red"></i>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="col text-right font-weight-bolder h4 m-0 p-0 pr-3"></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            <tr className="align-middle" key={bulkItem?.id}>
                                                <td>{bulkItem?.line_item_sku}</td>
                                                <td>{bulkItem?.line_item_name}</td>
                                                <td className="text-center">{bulkItem?.line_item_quantity}</td>
                                                <td>$ {bulkItem?.line_item_price}</td>
                                                <td>$ {bulkItem?.total_price}</td>
                                                <td className="text-center">{bulkItem?.gift_wrapping ? "YES" : "NO"}</td>
                                                <td>{bulkItem?.first_name}</td>
                                                <td>{bulkItem?.last_name}</td>
                                                <td>{bulkItem?.customer_email}</td>
                                                <td>{bulkItem?.shipping_company}</td>
                                                <td>{bulkItem?.shipping_phone}</td>
                                                <td>{bulkItem?.shipping_address_1}</td>
                                                <td>{bulkItem?.shipping_address_2}</td>
                                                <td>{bulkItem?.shipping_city}</td>
                                                <td>{bulkItem?.shipping_state?.name}</td>
                                                <td>{bulkItem?.shipping_zip}</td>
                                                <td className="position-sticky right-0 bg-lighter">
                                                    <div className="row border-left border-light align-items-center" style={{ width: "15rem", zIndex: 9999 }}>
                                                        <div className="col pl-1">
                                                            {bulkOrderDetails?.workflow !== "done" &&
                                                            bulkOrderDetails?.workflow !== "invoice" &&
                                                            bulkOrderDetails?.workflow !== "order_under_review" ? (
                                                                <span style={{ display: "flex", gap: "2rem" }}>
                                                                    <span
                                                                        onClick={() => {
                                                                            editBulkOrder(bulkItem);
                                                                        }}
                                                                        style={{ cursor: "pointer" }}>
                                                                        <i className="fas fa-edit fa-fw text-default"></i>
                                                                    </span>
                                                                    <span
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            handleDeleteBulkItem(bulkItem?.id, bulkItem?.first_name, bulkItem?.last_name);
                                                                        }}>
                                                                        <i className="fas fa-trash-alt fa-fw text-red"></i>
                                                                    </span>
                                                                </span>
                                                            ) : (
                                                                (bulkOrderDetails?.workflow === "done" ||
                                                                    bulkOrderDetails?.workflow === "order_under_review") && (
                                                                    <div div className="text-center font-weight-bold">
                                                                        {!bulkItem?.has_error ? (
                                                                            <>
                                                                                <i
                                                                                    className="fas fa-check-circle fa-2x text-success"
                                                                                    id={`tooltipSuccess-${bulkItem?.id}`}></i>
                                                                                <Tooltip
                                                                                    placement="top"
                                                                                    isOpen={tooltipSuccess[bulkItem.id]}
                                                                                    target={`tooltipSuccess-${bulkItem.id}`}
                                                                                    toggle={() => toggleSuccess(bulkItem.id)}>
                                                                                    Order created and completed successfully!
                                                                                </Tooltip>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <i
                                                                                    className="fas fa-check-circle fa-2x text-orange"
                                                                                    id={`tooltipReview-${bulkItem?.id}`}></i>
                                                                                <Tooltip
                                                                                    placement="top"
                                                                                    isOpen={tooltipReview[bulkItem.id]}
                                                                                    target={`tooltipReview-${bulkItem.id}`}
                                                                                    toggle={() => toggleReview(bulkItem.id)}>
                                                                                    Order under review
                                                                                </Tooltip>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                        <div className="col text-right font-weight-bolder h4 m-0 p-0 pr-3">
                                                            <div className="">$ {bulkItem?.total_price}</div>
                                                            {bulkItem?.id && bulkOrderDetails?.shippable?.includes(bulkItem?.id) ? (
                                                                <small className="small">
                                                                    Shipping: <strong>$ {bulkOrderDetails?.shipping}</strong>
                                                                </small>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="mt-4">
                    <div className="card bulk-order-detail">
                        <dl className="row card-body justify-content-end">
                            <dt className="h3 col-9 py-2 text-right">Bottles Total:</dt>
                            <dd className="col-3 py-2 text-right h3 text-muted" style={{ backgroundColor: "#fafafc" }}>
                                $ {bulkOrderDetails?.bottle_total}
                            </dd>
                            <dt className="h4 col-9 py-2 text-right">Shipping:</dt>
                            <dd className="col-3 py-2 text-right h3 font-weight-lighter" style={{ backgroundColor: "#fafafc" }}>
                                $ {bulkOrderDetails?.shipping_total}
                            </dd>
                            <dt className="h4 col-9 py-2 text-right">Tax:</dt>
                            <dd className="col-3 py-2 text-right h3 font-weight-lighter" style={{ backgroundColor: "#fafafc" }}>
                                {bulkOrderDetails?.draft_order ? `$ ${bulkOrderDetails?.taxes}` : "Calculated when invoice is generated"}
                            </dd>
                            <dt className="h2 col-9 py-2 text-right">Total:</dt>
                            <dd className="col-3 py-2 text-right h2" style={{ backgroundColor: "#fafafc" }}>
                                $ {bulkOrderDetails?.grand_total}
                            </dd>
                        </dl>
                    </div>
                </div>
                <hr />
                <div className="mb-3">
                    <div className="d-flex justify-content-between">
                        <div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <button className="btn btn-lg" onClick={() => history.push("/bulk-orders")}>
                                    ← Back
                                </button>
                            </div>
                        </div>
                        <div>
                            {bulkOrderDetails?.workflow !== "done" &&
                            bulkOrderDetails?.workflow !== "invoice" &&
                            bulkOrderDetails?.workflow !== "ordering" &&
                            bulkOrderDetails?.workflow !== "pending" ? (
                                <>
                                    <button
                                        className="btn btn-success btn-lg"
                                        id="invoiceTooltip"
                                        disabled={bulkOrderDetails?.error_count || bulkOrderDetails?.out_of_stock_count || generatingInvoice}
                                        onClick={() => generateInvoice(bulkOrderDetails?.id)}>
                                        Generate Invoice {generatingInvoice ? <Spinner size="sm" color="light" /> : null}
                                    </button>{" "}
                                    {bulkOrderDetails?.error_count || bulkOrderDetails?.out_of_stock_count ? (
                                        <Tooltip placement="top" isOpen={tooltipOpen} target="invoiceTooltip" toggle={toggle}>
                                            Please Fix all the errors before generating invoice!
                                        </Tooltip>
                                    ) : null}
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    );
};

export default BulkDetails;
