// 7057092675 - Component for standard rate listing
import React, { useEffect, useState } from "react";
import { Card, CardFooter, CardHeader, Col, Container, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";
import { api } from "services/api";
import Moment from "react-moment";
import PagePagination from "feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import { getParams } from "utils/commonFunctions";
import useFilter from "customHooks/useFilter";
import { prefixAndFilterMapping } from "customHooks/useFilter";
import { Sort, handleSort } from "../../utils/commonFunctions";

const StandardRateTable = ({ storeId, rateSaveResponse }) => {
    const [standardRate, setStandardRate] = useState();
    const [standardRateCount, setStandardRateCount] = useState();
    const [isLoading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        }
    });
    const [clearall, setClearAll] = useState(false);
    const isMobile = useMobileDetection(mobileScreenThresholdValue);
    const [filter, setFilter, clearFilter] = useFilter({});
    const [sort, setSort] = useState("-id");
    useEffect(() => {
        setLoading(true);
        let new_params = getParams(filter, setClearAll);
        new_params = {
            ...new_params,
            page,
            ordering: sort
        };
        const endpoint = storeId
            ? `order/shipping-chargebacks/overrides/store/standard-rates/listing?store_id=${storeId}`
            : `order/shipping-chargebacks/overrides/store/standard-rates/listing`;

        api.get(endpoint)
            .then(response => {
                setStandardRate(response);
                setStandardRateCount(response?.data?.count);
                const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
                const previous = page !== 1 ? page - 1 : 1;
                const next = page !== totalPages ? page + 1 : totalPages;
                setPagination({
                    previous,
                    next,
                    pages: () => {
                        let startCount = 1;
                        let endCount = isMobile ? 4 : 12;
                        let numberCount = Math.round(endCount / 2);
                        const pageList = [];
                        if (numberCount < 0) numberCount = 1;
                        startCount = page - numberCount;
                        if (startCount <= 0) startCount = 1;
                        if (page !== 1) endCount = page + numberCount;
                        if (endCount > totalPages) endCount = totalPages;
                        if (totalPages >= endCount) {
                            for (let i = startCount; i <= endCount; i++) {
                                pageList.push(i);
                            }
                        } else if (totalPages >= 1) {
                            for (let i = 1; i <= totalPages; i++) {
                                pageList.push(i);
                            }
                        }
                        return pageList;
                    }
                });
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.error("Error", error);
            });
    }, [storeId, page, sort, filter, refresh, isMobile]);
    const handleRefresh = () => {
        setRefresh(!refresh);
    };
    const handlePagination = page => {
        setPage(page);
    };
    useEffect(() => {
        if (rateSaveResponse) {
            setRefresh(!refresh); // Trigger a refresh
        }
    }, [rateSaveResponse]);
    return (
        <Card className="shadow mt-3">
            <CardHeader className="border-0">
                <Row className={"mb-2"}>
                    <Col xs={10} lg={"2"} className={"text-left"}></Col>
                    <Col xs={2} lg={"10"} className={"text-right"}>
                        <div className={"d-none d-lg-inline-block"}>
                            <button className="button-font-size btn btn-neutral" onClick={handleRefresh}>
                                <i className="fas fa-sync-alt"></i>
                            </button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ display: "flex" }}>
                        <h3 className="mb-0">
                            Store Standard Rates
                            <small> ({standardRateCount})</small>
                        </h3>
                    </Col>
                </Row>
            </CardHeader>
            <div>
                {isLoading ? (
                    <div className="text-center mt-3 mb-3">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" className="pl-3 pr-3">
                                        Rate ID
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Rate Name
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Created At
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Condition type
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Minimum Weight
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Maximum Weight
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Minimum Price
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Maximum Price
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Rate value
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Store name
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Brands
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        States
                                    </th>
                                    <th scope="col" className="pl-3 pr-3">
                                        Inventory Location
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {standardRate?.data?.results &&
                                    standardRate.data.results.map(rate => (
                                        <tr key={rate.id}>
                                            <td className={"pr-3 pl-3"}>{rate?.id}</td>
                                            <td className={"pr-3 pl-3"}>{rate?.rate_name}</td>
                                            <td className={"pr-3 pl-3"}>
                                                <Moment format="MM/D/YYYY" withTitle>
                                                    {rate?.created_at}
                                                </Moment>
                                            </td>
                                            <td className={"pr-3 pl-3"}>{rate?.condition_type_name}</td>
                                            <td className={"pr-3 pl-3"}>{rate?.minimum_weight || "-"}</td>
                                            <td className={"pr-3 pl-3"}>{rate?.maximum_weight || "-"}</td>
                                            <td className={"pr-3 pl-3"}>{rate?.minimum_price || "-"}</td>
                                            <td className={"pr-3 pl-3"}>{rate?.maximum_price || "-"}</td>
                                            <td className={"pr-3 pl-3"}>{rate?.rate_value}</td>
                                            <td className={"pr-3 pl-3"}>{rate?.store_names}</td>
                                            <td className={"pr-3 pl-3"}>{rate?.brand_names?.join(", ")}</td>
                                            <td className="pr-3 pl-3">
                                                {Array.isArray(rate?.state_names)
                                                    ? rate?.state_names.map((state, index, array) => (
                                                          <span key={index}>
                                                              {state}
                                                              {index !== array.length - 1 ? ", " : ""}
                                                              {(index + 1) % 3 === 0 && index !== array.length - 1 ? <br /> : ""}
                                                          </span>
                                                      ))
                                                    : rate?.state_names}
                                            </td>
                                            <td className="pr-3 pl-3">
                                                {Array.isArray(rate?.inventory_location_names)
                                                    ? rate?.inventory_location_names.map((state, index, array) => (
                                                          <span key={index}>
                                                              {state}
                                                              {index !== array.length - 1 ? ", " : ""}
                                                              {(index + 1) % 3 === 0 && index !== array.length - 1 ? <br /> : ""}
                                                          </span>
                                                      ))
                                                    : rate?.inventory_location_names}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                            <CardFooter className="text-center border-top-0">
                                <nav aria-label="...">
                                    {pagination.pages().length > 1 && (
                                        <Pagination className="pagination justify-content-center mb-0" listClassName="justify-content-center mb-0">
                                            <PaginationItem className="">
                                                <PaginationLink href="#" onClick={e => handlePagination(pagination?.previous)} tabIndex="-1">
                                                    <i className="fas fa-angle-left" />
                                                    <span className="sr-only">Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            {pagination.pages().map((pageIndex, index) => (
                                                <PaginationItem key={index} className={page === pageIndex ? "active" : ""}>
                                                    <PaginationLink href="#" onClick={e => handlePagination(pageIndex)}>
                                                        {pageIndex}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            ))}
                                            <PaginationItem>
                                                <PaginationLink href="#" onClick={e => handlePagination(pagination?.next)}>
                                                    <i className="fas fa-angle-right" />
                                                    <span className="sr-only">Next</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    )}
                                </nav>
                            </CardFooter>
                        </Table>
                    </>
                )}
            </div>
        </Card>
    );
};

export default StandardRateTable;
