import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Card,
    CardHeader,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    UncontrolledDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Col,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Input,
} from "reactstrap";
import { AuthContext } from "contexts/AuthContext";
import { api } from 'services/api';
import Filter from 'components/Filters/Filter';
import Moment from 'react-moment';
import BadgePillFilter from 'components/Filters/Badge';
import DateRange from "../../components/Filters/DateRange";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { ComponentContext } from "../../contexts/ComponentContext";
import { useLocation } from "react-router-dom";
import axios from "axios";
import fileDownload from "js-file-download";
import useFilter from 'customHooks/useFilter';
import { getParams } from 'utils/commonFunctions';
import { removeBadge } from 'utils/commonFunctions';
import { addURLParam, processURLSearchParams, removeAllURLParams, removeURLParam } from "utils/urls";
import useCustomState from 'customHooks/useCustomState';
import { prefixAndFilterMapping } from 'customHooks/useFilter';
import { Sort, handleSort } from "../../utils/commonFunctions";
import PagePagination from 'feature/pagination/Pagination';
import useMobileDetection from 'customHooks/useMobileDetection';
import { mobileScreenThresholdValue } from 'utils/constants';

const Customer = () => {
    const { user, isAuthentication } = useContext(AuthContext);
    const [customerList, setCustomerList] = useState();
    const [customerCount, setCustomerCount] = useState();
    const [isLoading, setLoading] = useState(false);
    const [clearall, setClearAll] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [params, setParams] = useState([]);
    const [page, setPage] = useState(1);
    const [selected, setSelected] = useState([]);
    const [sort, setSort] = useState("-id");
    const [sortOrder, setSortOrder] = useState({order: "", columnName: ""});
    const [filter, setFilter, clearFilter] = useFilter({});
    const cancelToken = React.useRef(undefined);
    const { addToast } = useToasts();
    const { component } = useContext(ComponentContext);
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        },
    });
    const isMobile = useMobileDetection(mobileScreenThresholdValue);

    useEffect(() => {
        setLoading(true);
        let new_params = getParams(filter, setClearAll);
        new_params = {
            ...new_params,
            page,
            ordering: sort,
        };
        if (typeof cancelToken.current != undefined) {
            cancelToken.current !== undefined && cancelToken.current.cancel("Operation canceled due to new request.");
        }
        cancelToken.current = axios.CancelToken.source();
        api.get('/customers/list', {
            params: new_params,
            cancelToken: cancelToken.current.token
        })
            .then((response) => {
                const list = response.data.results.map((customer) => {
                    const s = selected.filter((item) => parseInt(item.id) === customer.order_id);
                    customer.checked = s.length > 0;
                    return customer;
                })
                setCustomerList(response);
                setCustomerCount(response.data.count);
                const totalPages = Math.ceil(
                    response.data.count / window.env.PAGE_SIZE
                );
                const previous = page !== 1 ? page - 1 : 1;
                const next = page !== totalPages ? page + 1 : totalPages;
                setPagination({
                    previous,
                    next,
                    pages: () => {
                      let startCount = 1;
                      let endCount = isMobile ? 4 : 12;
                      let numberCount = Math.round(endCount / 2);
                      const pageList = [];
                      if (numberCount < 0) numberCount = 1;
                      startCount = page - numberCount;
                      if (startCount <= 0) startCount = 1;
                      if (page !== 1) endCount = page + numberCount;
                      if (endCount > totalPages) endCount = totalPages;
                      if (totalPages >= endCount) {
                        for (let i = startCount; i <= endCount; i++) {
                          pageList.push(i);
                        }
                      } else if (totalPages >= 1) {
                        for (let i = 1; i <= totalPages; i++) {
                          pageList.push(i);
                        }
                      }
                      return pageList;
                    }
                  });
                setLoading(false);
                delete new_params["page"];
                delete new_params["ordering"];
                setParams(new_params);
            })
            .catch(error => console.log(error))
    }, [page, sort, refresh, filter, component]);
    const refCustomerCheckbox = useRef(false);
    const handleCustomerCheckbox = (e) => {
        refCustomerCheckbox.current = e.target.checked;
        let checkboxes = document.querySelectorAll(".customerCheckbox");
        let items = [];
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = e.target.checked;
            if (e.target.checked === true) {
                items.push({ id: checkboxes[i].value, title: checkboxes[i].name });
            }
        }
        setSelected(items);
    };
    const urlSearchParams = useLocation().search;
    React.useMemo(() => {
        if (filter.urlParamsProcessed == false) processURLSearchParams(new URLSearchParams(urlSearchParams), filter, setFilter);
    }, [urlSearchParams]);
    const [exportButton, setExportButton] = useState({
        label: "Export",
        disabled: false,
    });
    const handleExportCustomer = () => {
        setExportButton({
            label: "Exporting...",
            disabled: true,
        });
        let new_params = params;
        new_params.customer_export_ids = selected.map((item) => item.id);
        api
            .get("export/customer/", {
                params: new_params,
            })
            .then((response) => response.data)
            .then((response) => {
                const success = response?.success | false;
                if (success) {
                    addToast(response.message, {
                        appearance: "success",
                        autoDismiss: false,
                    });
                } else {
                    const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
                    const filename = "customer_" + timestamp + ".csv";
                    fileDownload(response, filename);
                }
                setExportButton({
                    label: "Export",
                    disabled: false,
                });
            });
    };
    const handleCheckbox = (e) => {
        let checkbox = [...new Set(selected)];
        if (e.target.checked) {
            checkbox.push({ id: e.target.value, title: e.target.name });
        } else {
            checkbox = checkbox.filter(item => item.id !== e.target.value);
        }
        setSelected(checkbox);
    };
    const handleRefresh = () => {
        setRefresh(!refresh);
    };
    const handlePagination = (page) => {
        setPage(page);
    };
    /**
     * Generic function for filters
     * @param {*} event 
     * @param {*} type 
     */
    const handleFilters = (event, type) => {
        setPage(1);
        if (event == "__all__") {
            let selectedOptions = [];
            let checkbox = document.querySelectorAll(`.checkbox_${type}`);
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = true;
                selectedOptions.push({
                    value: checkbox[i].value,
                    label: checkbox[i].dataset.label,
                });
            }
            let temporaryFilter = { ...filter }
            temporaryFilter[prefixAndFilterMapping[type]] = selectedOptions
            setFilter(temporaryFilter);
        } else {
            let selection = [...new Set(filter[type])]
            if (event.target.checked) {
                selection.push({ value: event.target.value, label: event.target.dataset.label })
                addURLParam(window, type, event.target.value);
            } else {
                selection = selection.filter(item => item.value !== event.target.value)
                removeURLParam(window, type, event.target.value)
            }
            setFilter({ ...filter, [type]: selection })
        }
    }
    /**
     * Clear Filter function for individual filter
     * @param {*} prefix 
     */
    const clearFilterCustom = (prefix) => {
        setPage(1);
        if (prefix === "brand") {
            setFilter({
                ...filter, brands: []
            });
        }
        else if (prefix === "city") {
            setFilter({
                ...filter, cities: []
            });
        } else if (prefix === "customer_states") {
            setFilter({
                ...filter, customer_states: []
            });
        } else if (prefix === "accepts_marketing") {
            setFilter({
                ...filter, accepts_marketing: []
            });
        }
        removeURLParam(window, prefixAndFilterMapping[prefix]);
        setClearAll(false);
        let checkbox = document.querySelectorAll('.checkbox_' + prefix + ':checked');
        if (checkbox.length > 0) {
            for (let i = 0; i < checkbox.length; i++) {
                checkbox[i].checked = false;
            }
        }
    }
    const handleClearAll = () => {
        setPage(1);
        setClearAll(false);
        setSelected([]);
        window.history.replaceState({}, "", `${window.location.pathname}`);
        document.getElementById("customer-page-search-input").value = "";
        clearFilter();
        removeAllURLParams();
    }
    const handleDateRange = (start, end) => {
        setPage(1);
        addURLParam(window, "customerStartDate", start);
        addURLParam(window, "customerEndDate", end);
        setFilter({
            ...filter,
            // 6367053025 - Generic Date Filter bug changes
            customerStartDate: start,
            customerEndDate: end,
        });
    };
    let timer = null;
    const handleSearch = (e) => {
        setPage(1);
        addURLParam(window, "search", e.target.value);
        clearTimeout(timer);
        timer = setTimeout(function () {
            setFilter({
                ...filter,
                search: e.target.value
            })
        }, 750);
    };
    const searchInput=document.getElementById("customer-page-search-input")
    return (
        <>
            {isAuthentication ? (
                <Container fluid>
                    <div className={"pt-7"}>
                        {isAuthentication && (
                            <Row className={"mb-2"}>
                                <Col xs={10} lg={"2"} className={"text-left"}>
                                    {/* 7278215305 - increase font size */}
                                    <input
                                        type="text"
                                        id="customer-page-search-input"
                                        prefix={"search"}
                                        className={"button-font-size form-control form-control-sm"}
                                        placeholder={"Name, Email or Zip"}
                                        defaultValue={filter.search}
                                        onKeyDown={handleSearch}
                                        newparams={""}
                                    />
                                </Col>
                                <Col xs={2} lg={"10"} className={"text-right"}>
                                    <div className={"d-none d-lg-inline-block"}>
                                        {/* 7278215305 - increase font size */}
                                        <button
                                            className="button-font-size btn btn-neutral"
                                            onClick={handleRefresh}
                                        >
                                            <i className="fas fa-sync-alt"></i>
                                        </button>
                                        <Filter
                                            name={"Accepts Marketing"}
                                            prefix={"accepts_marketing"}
                                            onChange={(event) => handleFilters(event, "accepts_marketing")}
                                            newparams={params}
                                            selected={filter.accepts_marketing}
                                            url={"customers/filters/accepts-marketing"}
                                            inputSearch={false}
                                            onClear={() => clearFilterCustom("accepts_marketing")}
                                        />
                                        <Filter
                                            name={"Brands"}
                                            prefix={"brands"}
                                            onChange={(event) => handleFilters(event, "brands")}
                                            newparams={params}
                                            selected={filter.brands}
                                            url={"customers/filters/brands"}
                                            onClear={() => clearFilterCustom("brand")}
                                        />
                                        <Filter
                                            name={"Cities"}
                                            prefix={"cities"}
                                            onChange={(event) => handleFilters(event, "cities")}
                                            newparams={params}
                                            selected={filter.cities}
                                            url={"customers/filters/cities"}
                                            onClear={() => clearFilterCustom("city")}
                                        />
                                        <Filter
                                            name={"States"}
                                            prefix={"customer_states"}
                                            onChange={(event) => handleFilters(event, "customer_states")}
                                            newparams={params}
                                            selected={filter.customer_states}
                                            url={"customers/filters/states"}
                                            onClear={() => clearFilterCustom("customer_states")}
                                        />
                                        <DateRange onCallback={handleDateRange} button_name={"Date"}></DateRange>
                                        {/* 7278215305 - increase font size */}
                                        <Button
                                            color={"primary"}
                                            disabled={exportButton.disabled}
                                            onClick={handleExportCustomer}
                                            className='button-font-size'
                                        >
                                            <i className="fas fa-file-csv" /> Export
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            {isAuthentication && (
                                <div className="col">
                                    <Card className="shadow mt-3">
                                        <CardHeader className="border-0">
                                            <Row>
                                                <Col style={{ display: "flex" }}>
                                                    <h3 className="mb-0">
                                                        Customers
                                                        <small> ({customerCount})</small>
                                                    </h3>
                                                    {clearall &&
                                                        <a href={void (0)}
                                                            className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                                                            onClick={handleClearAll}
                                                            id="clear_all_filters">
                                                            <i className="fas fa-times-circle"></i> Clear All filters
                                                        </a>
                                                    }
                                                </Col>
                                            </Row>
                                            <Col sm="12">
                                                <BadgePillFilter
                                                    data={filter}
                                                    selected={selected}
                                                    title={'customer'}
                                                    onClick={(e) => {
                                                        removeBadge(e, filter, setFilter, selected, setSelected,() =>{}, searchInput, setPage)
                                                    }}
                                                />
                                            </Col>
                                        </CardHeader>
                                    </Card>
                                </div>
                            )}
                        </Row>
                        {isLoading ? (
                            <div className="text-center mt-3 mb-3">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <>
                                <Table
                                    className="align-items-center table-flush"
                                    responsive
                                >
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col" className="pl-3 pr-3">
                                                <input
                                                    type="checkbox"
                                                    name={"check_fulfillment"}
                                                    id={"checkbox_fulfillment"}
                                                    ref={refCustomerCheckbox}
                                                    defaultChecked={refCustomerCheckbox.current}
                                                    onChange={handleCustomerCheckbox}
                                                />
                                            </th>
                                            <th scope="col" className="pl-1">
                                                <Sort onClick={() => handleSort(sort, "name", setSortOrder, setSort)} sortingOrder={sortOrder} column="name">
                                                    Name
                                                </Sort>
                                            </th>
                                            <th scope="col" className="pl-1">
                                                <Sort onClick={() => handleSort(sort, "email", setSortOrder, setSort)} sortingOrder={sortOrder} column="email">
                                                    Email
                                                </Sort>
                                            </th>
                                            <th scope="col" className="pl-1">
                                                <Sort onClick={() => handleSort(sort, "city", setSortOrder, setSort)} sortingOrder={sortOrder} column="city">
                                                    City
                                                </Sort>
                                            </th>
                                            <th scope="col" className="pl-1">
                                                <Sort onClick={() => handleSort(sort, "state", setSortOrder, setSort)} sortingOrder={sortOrder} column="state">
                                                    State
                                                </Sort>
                                            </th>
                                            <th scope="col" className="pl-1">
                                                <Sort onClick={() => handleSort(sort, "zip", setSortOrder, setSort)} sortingOrder={sortOrder} column="zip">
                                                    Zip
                                                </Sort>
                                            </th>
                                            <th scope="col" className="pl-1">
                                                <Sort onClick={() => handleSort(sort, "accepts_marketing", setSortOrder, setSort)} sortingOrder={sortOrder} column="accepts_marketing">
                                                    Email opt in
                                                </Sort>
                                            </th>
                                            <th scope="col" className="pl-1">
                                                <Sort onClick={() => handleSort(sort, "orders_count", setSortOrder, setSort)} sortingOrder={sortOrder} column="orders_count">
                                                    Orders
                                                </Sort>
                                            </th>
                                            <th scope="col" className="pl-1">
                                                Total spent
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customerList?.data?.results && customerList.data.results.map((customer) => (
                                            <tr key={customer.id}>
                                                <td className={"pr-3 pl-3"}>
                                                    <input
                                                        type="checkbox"
                                                        name={customer.id}
                                                        value={customer.id}
                                                        id={"checkbox_" + customer.id}
                                                        className={"customerCheckbox"}
                                                        onChange={(e) => handleCheckbox(e)}
                                                        defaultChecked={customer?.checked}
                                                    />
                                                </td>
                                                <td className="pl-1 pr-1">
                                                    <a  href={`/customer-details?${new URLSearchParams({ customerId: `${customer?.id}` }).toString()}`} className="btn btn-link p-0">
                                                        {customer.name}
                                                    </a>
                                                </td>
                                                <td scope="col" className="pl-1">
                                                    {customer.email}
                                                </td>
                                                <td scope="col" className="pl-1">
                                                    {customer.city || "None"}
                                                </td>
                                                <td scope="col" className="pl-1">
                                                    {customer.state || "None"}
                                                </td>
                                                <td scope="col" className="pl-1">
                                                    {customer.zip || "None"}
                                                </td>
                                                <td scope="col" className="pl-1">
                                                    {customer.accepts_marketing == 1 ? <div style={{ color: "green" }}>Yes</div> : <div style={{ color: "red" }}>No</div>}
                                                </td>
                                                <td scope="col" className="pl-1">
                                                    {customer.orders_count || "-"}
                                                </td>
                                                <td scope="col" className="pl-1">
                                                    {customer.total_spent || "-"}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                    <PagePagination
                                        pagination={pagination}
                                        handlePagination={handlePagination}
                                        page={page}
                                        totalCount={customerCount}
                                    />
                            </>
                        )
                        }
                    </div>
                </Container>
            ) :
                null
            }
        </>
    )
}
export default Customer;