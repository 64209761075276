import React from "react";
import { Table } from "reactstrap";
import { Sort, handleSort } from "../../utils/commonFunctions";
import ChildOrderTableRow from "./ChildOrderTableRow";

const ChildOrderTable = ({
    childOrderData,
    user,
    sort,
    setSort,
    setSortOrder,
    sortOrder,
}) => {
    return (
        <div className="col p-0">
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <tr>
                        <th scope="col" className="pl-3 pr-3">
                            <Sort
                                onClick={() => handleSort(sort, "id", setSortOrder, setSort)}
                                sortingOrder={sortOrder}
                                column="id">
                                    Batch ID
                            </Sort>
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            <Sort
                                onClick={() => handleSort(sort, "parent_order__name", setSortOrder, setSort)}
                                sortingOrder={sortOrder}
                                column="parent_order__name">
                                    Parent Order
                            </Sort>
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                                Existing Child Orders
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            <Sort onClick={() => handleSort(sort, "child_order_type", setSortOrder, setSort)} sortingOrder={sortOrder} column="child_order_type">
                                Child Order Type
                            </Sort>
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            <Sort onClick={() => handleSort(sort, "new_child_order__name", setSortOrder, setSort)} sortingOrder={sortOrder} column="new_child_order__name">
                                New Child Order
                            </Sort>
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            Parent Order Line Item Selected
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            Child Order Line Item(s) Selected
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            <Sort onClick={() => handleSort(sort, "batch_status", setSortOrder, setSort)} sortingOrder={sortOrder} column="batch_status">
                                Batch Status
                            </Sort>
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            <Sort onClick={() => handleSort(sort, "submitted_user", setSortOrder, setSort)} sortingOrder={sortOrder} column="submitted_user">
                                Submitted User
                            </Sort>
                        </th>
                        <th scope="col" className="pl-3 pr-3">
                            Date Submitted
                        </th>
                        {user?.isAdmin && (
                            <th scope="col" className="pl-3 pr-3">
                                Billable User
                            </th>
                        )}
                        <th scope="col" className="pl-3 pr-3">
                            <Sort onClick={() => handleSort(sort, "error_message", setSortOrder, setSort)} sortingOrder={sortOrder} column="error_message">
                                Error Message
                            </Sort>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {childOrderData?.map(childOrder => (
                        <ChildOrderTableRow key={childOrder?.id} chargeback={childOrder} />
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default ChildOrderTable;
