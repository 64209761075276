/**7105627296 - Improve Chargeback Calculations */
import React, { useEffect, useState, useRef } from "react";
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    CustomInput,
    Card,
    CardBody,
    CardHeader,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from "reactstrap";
import { State_listoptions, EastCoastStateOptions, WestCoastStateOptions } from "../../utils/states";
import Select from "react-select";
import { api } from "services/api";
import { useToasts } from "react-toast-notifications";
import { useLocation } from "react-router-dom";
import { CustomMultiSelectDropdown } from "components/CustomDropdown/CustomDropdown";
import { FaTimes } from "react-icons/fa";
import "./StoreStandardRates.css";

const StandardRates = ({ storeId, isOpen, toggleModal, onSaveSuccess }) => {
    /** Conversion of Maryland states code to MD */
    const EastCoastStateOptionsLocal = EastCoastStateOptions.map(state => (state.value === "Maryland" ? { ...state, code: "MD" } : state));
    const AllStates = State_listoptions.map(state => (state.value === "Maryland" ? { ...state, code: "MD" } : state));
    const location = useLocation();

    const [rates, setRates] = useState([
        {
            rateName: "",
            rateValue: "",
            brand: "",
            condition: "",
            minPrice: 0,
            maxPrice: 0,
            minWeight: 0,
            maxWeight: 0,
            states: [],
            selectedBrands: [],
            selectedStates: []
        }
    ]);
    const [errors, setErrors] = useState([{}]);

    const lastCardRef = useRef(null);
    const lastRateNameRef = useRef(null);

    const addNewRate = () => {
        setRates([...rates, {
            rateName: "",
            rateValue: "",
            brand: "",
            condition: "",
            minPrice: 0,
            maxPrice: 0,
            minWeight: 0,
            maxWeight: 0,
            states: [],
            selectedBrands: [],
            selectedStates: []
        }]);
        setErrors([...errors, {}]); // Add a new error object for the new rate
        setSelectedInventoryLocations([...selectedInventoryLocations, []]); // Add a new inventory location for the new rate
        setShowBanner(true); // Show the banner
        setTimeout(() => {
            setShowBanner(false); // Hide the banner and the footer message after 2 seconds
        }, 2000);
    };

    const deleteRate = index => {
        if (rates.length === 1) return; // Prevent deletion if there's only one card

        const newRates = rates.filter((_, i) => i !== index);
        const newErrors = errors.filter((_, i) => i !== index);
        const newInventoryLocations = selectedInventoryLocations.filter((_, i) => i !== index);
        setRates(newRates);
        setErrors(newErrors);
        setSelectedInventoryLocations(newInventoryLocations); // Update inventory location state
    };

    const [brandValues, setBrandValues] = useState([]);
    const [showBanner, setShowBanner] = useState(false);
    const [inventoryLocationList, setInventoryLocationList] = useState([]);
    const [selectedInventoryLocations, setSelectedInventoryLocations] = useState([[]]);

    const queryParams = new URLSearchParams(location.search);
    const store_id = queryParams.get("storeId");

    /**
     * useEffect for brands dropdown
     */
    useEffect(() => {
        api.get("order/shipping-chargebacks/overrides/store/brands", {
            params: { store_id: store_id }
        })
            .then(response => {
                const options = response?.data?.results || [];
                const brandOptions = options.map(item => ({
                    value: item.id,
                    label: item.name
                }));
                setBrandValues(brandOptions);
            })
            .catch(err => {
                console.log(err);
            });
    }, [store_id]);

    useEffect(() => {
        api
            .get("inventory-location-list/")
            .then((response) => response)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    let inventoryLocations = response.data.map((item) => ({
                        value: item.id,
                        label: item.name,
                    }));
                    setInventoryLocationList(inventoryLocations);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    useEffect(() => {
        if (!isOpen) {
            setErrors([{}]);
            resetRates();
            setSelectedInventoryLocations([[]]);
        }
    }, [isOpen]);

    const topOptions = [
        { value: "East Coast", label: "East Coast" },
        { value: "West Coast", label: "West Coast" }
    ];
    const { addToast } = useToasts();

    /**
     * Combining all states, east coast and west coast
     */
    const allOptions = [...topOptions, ...AllStates];

    const handleBrandValue = (index, selectedOptions) => {
        const newRates = [...rates];
        newRates[index].selectedBrands = selectedOptions || [];
        setRates(newRates);
    };

    /**
     * Function for States dropdown
     * @param {*} index
     * @param {*} selectedOptions
     */
    const handleChange = (index, selectedOptions) => {
        // Check for special cases in the selected options
        const isSelectAll = selectedOptions.some(option => option.value === "__all__");
        const isEastCoast = selectedOptions.some(option => option.value === "East Coast");
        const isWestCoast = selectedOptions.some(option => option.value === "West Coast");

        let newDropdownValue = [];

        if (isSelectAll) {
            // Include all options except special ones
            newDropdownValue = allOptions.filter(option => option.value !== "__all__" && option.value !== "East Coast" && option.value !== "West Coast");
        } else {
            if (isEastCoast) {
                // Add East Coast states
                newDropdownValue = newDropdownValue.concat(EastCoastStateOptionsLocal);
            }
            if (isWestCoast) {
                // Add West Coast states
                newDropdownValue = newDropdownValue.concat(WestCoastStateOptions);
            }
            // Add individually selected states
            selectedOptions.forEach(option => {
                if (option.value !== "East Coast" && option.value !== "West Coast" && option.value !== "__all__") {
                    newDropdownValue.push(option);
                }
            });
        }

        // Update the rates state with the new selected states for the given index
        const newRates = [...rates];
        newRates[index].selectedStates = newDropdownValue;
        setRates(newRates);
    };

    const handleInventoryLocationChange = (index, selectedOptions) => {
        const newSelectedInventoryLocations = [...selectedInventoryLocations];
        newSelectedInventoryLocations[index] = selectedOptions || [];
        setSelectedInventoryLocations(newSelectedInventoryLocations);
    };

    /**
     * Function for handling input change
     * @param {*} index
     * @param {*} field
     * @param {*} value
     */
    const handleInputChange = (index, field, value) => {
        const newErrors = [...errors];
        const newRates = [...rates];
        let sanitizedValue = value;

        // Apply sanitization only to numeric fields
        if (field === "rateValue" || field === "minWeight" || field === "maxWeight" || field === "minPrice" || field === "maxPrice") {
            // Remove non-numeric characters except a single decimal point
            sanitizedValue = value.replace(/[^0-9.]/g, "");

            // Ensure the value doesn't start with a decimal point or contain multiple decimal points
            const parts = sanitizedValue.split(".");
            if (parts.length > 2) {
                sanitizedValue = parts[0] + "." + parts.slice(1).join(""); // Reconstruct the number without extra decimal points
            }

            // Restrict to two decimal places
            if (parts[1] && parts[1].length > 2) {
                sanitizedValue = `${parts[0]}.${parts[1].substring(0, 2)}`;
            }

            // Prevent entering 'e' or 'E' in the input
            sanitizedValue = sanitizedValue.replace(/[eE]/g, "");

            // Update the rate field
            newRates[index][field] = sanitizedValue;

            // Clear any previous errors related to this field
            newErrors[index] = { ...newErrors[index], [field]: null };
        } else {
            // For non-numeric fields
            newRates[index][field] = value;
        }
        // Clearing specific errors when valid input is entered
        if (field === "rateName" && sanitizedValue) {
            newErrors[index] = { ...newErrors[index], rateName: null };
        }

        if (field === "rateValue" && sanitizedValue) {
            newErrors[index] = { ...newErrors[index], rateValue: null };
        }

        if (field === "condition" && sanitizedValue) {
            newErrors[index] = { ...newErrors[index], condition: null };
        }

        // Check for specific conditions and errors

        // Weight validation
        if (field === "minWeight" || field === "maxWeight") {
            const minWeight = parseFloat(newRates[index].minWeight || 0);
            const maxWeight = parseFloat(newRates[index].maxWeight || 0);

            if (newRates[index].minWeight && newRates[index].maxWeight && maxWeight <= minWeight) {
                newErrors[index] = { ...newErrors[index], maxWeight: "Maximum Weight must be greater than Minimum Weight" };
            } else {
                newErrors[index] = { ...newErrors[index], maxWeight: null };
            }

            // Clear the error if either minWeight or maxWeight has a value
            if (newRates[index].minWeight || newRates[index].maxWeight) {
                newErrors[index] = { ...newErrors[index], weightError: null };
            }
        }

        // Price validation
        if (field === "minPrice" || field === "maxPrice") {
            const minPrice = parseFloat(newRates[index].minPrice || 0);
            const maxPrice = parseFloat(newRates[index].maxPrice || 0);

            if (newRates[index].minPrice && newRates[index].maxPrice && maxPrice <= minPrice) {
                newErrors[index] = { ...newErrors[index], maxPrice: "Maximum Price must be greater than Minimum Price" };
            } else {
                newErrors[index] = { ...newErrors[index], maxPrice: null };
            }

            // Clear the error if either minPrice or maxPrice has a value
            if (newRates[index].minPrice || newRates[index].maxPrice) {
                newErrors[index] = { ...newErrors[index], priceError: null };
            }
        }
        if (field === "condition") {
            // Clear the error message for condition when a valid condition is selected
            newErrors[index] = { ...newErrors[index], condition: null };
        }

        // Set the updated state
        setRates(newRates);
        setErrors(newErrors);
    };

    const inputRef = useRef(null); // Add this at the top of your component

    useEffect(() => {
        const inputElement = inputRef.current;

        if (inputElement) {
            const handleWheelEvent = event => {
                event.preventDefault();
            };

            inputElement.addEventListener("wheel", handleWheelEvent, { passive: false });

            return () => {
                inputElement.removeEventListener("wheel", handleWheelEvent);
            };
        }
    }, [inputRef]);

    /**
     * On submit of form function
     */
    const handleSaveRate = () => {
        let hasError = false;
        const newError = [...errors];

        rates.forEach((rate, index) => {
            // Check required fields
            if (!rate.rateName) {
                newError[index] = { ...newError[index], rateName: "Rate Name is required" };
                hasError = true;
            }
            if (!rate.rateValue) {
                newError[index] = { ...newError[index], rateValue: "Rate Value is required" };
                hasError = true;
            }
            if (!rate.condition) {
                newError[index] = { ...newError[index], condition: "Condition is required" };
                hasError = true;
            }

            // Check if both minPrice and maxPrice are empty
            if (rate.condition === "price" && !rate.minPrice && !rate.maxPrice) {
                newError[index] = { ...newError[index], priceError: "At least one of Minimum or Maximum Price must be populated" };
                hasError = true;
            }

            // Check if both minWeight and maxWeight are empty
            if (rate.condition === "weight" && !rate.minWeight && !rate.maxWeight) {
                newError[index] = { ...newError[index], weightError: "At least one of Minimum or Maximum Weight must be populated" };
                hasError = true;
            }

            // Check if maxPrice is less than minPrice
            if (rate.maxPrice && parseFloat(rate.maxPrice) <= parseFloat(rate.minPrice || 0)) {
                newError[index] = { ...newError[index], maxPrice: "Maximum Price must be greater than or equal to Minimum Price" };
                hasError = true;
            }

            // Check if maxWeight is less than minWeight
            if (rate.maxWeight && parseFloat(rate.maxWeight) <= parseFloat(rate.minWeight)) {
                hasError = true;
                newError[index] = { ...newError[index], maxWeight: "Maximum Weight must be greater than or equal to Minimum Weight" };
            }
        });

        setErrors(newError);

        // If there's an error, do not proceed with the API call
        if (hasError) {
            addToast("Please fix the errors before saving.", { appearance: "error", autoDismiss: true });
            return;
        }

        // Proceed with the API call if no errors are found
        const payload = rates.map((rate, index) => ({
            rate_name: rate.rateName,
            rate_value: rate.rateValue,
            condition_type: rate.condition === "weight" ? 1 : rate.condition === "price" ? 2 : null,
            minimum_weight: rate.minWeight || null,
            maximum_weight: rate.maxWeight || null,
            minimum_price: rate.minPrice || null,
            maximum_price: rate.maxPrice || null,
            brands: rate.selectedBrands.map(item => item.value),
            states: rate.selectedStates.map(item => item.code),
            store_id: store_id,
            inventory_locations: selectedInventoryLocations[index].map(item => item.value)
        }));

        api.post("order/shipping-chargebacks/overrides/store/standard-rates", payload)
            .then(response => {
                onSaveSuccess(response?.data);
                if (response?.data?.success === false) {
                    toggleModal();
                    addToast(response?.data?.message, { appearance: "error", autoDismiss: true });
                } else if (response.status === 201 && response?.data?.duplicate_records) {
                    const errorMessages = response?.data?.duplicate_records?.map(error => error?.rate_name).join(", ");
                    toggleModal();
                    if (payload?.length === response?.data?.duplicate_records?.length) {
                        addToast(`Some of the records already exist with the values: ${errorMessages}`, { appearance: "error", autoDismiss: true });
                    } else {
                        addToast(response?.data?.success, { appearance: "success", autoDismiss: true, autoClose: 10000 });
                        addToast(`Some of the records already exist with the values: ${errorMessages}`, {
                            appearance: "error",
                            autoDismiss: true,
                            autoClose: 10000
                        });
                    }
                } else {
                    toggleModal();
                    addToast(response?.data?.success, { appearance: "success", autoDismiss: true });
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (lastCardRef.current) {
            lastCardRef.current.scrollIntoView({ behavior: "smooth" });
        }
        // Adding a slight delay to ensure the field is rendered before focusing
        setTimeout(() => {
            if (lastRateNameRef.current) {
                console.log('Ref:', lastRateNameRef.current);
                lastRateNameRef.current.focus();
            }
        }, 50);
    }, [rates.length]);

    const handleInput = event => {
        const value = event.target.value;

        // Regular expression to allow only positive numbers and a single decimal point
        if (!/^\d*\.?\d*$/.test(value)) {
            event.target.value = value.replace(/[^0-9.]/g, ""); // Remove any non-numeric and non-decimal characters
        }

        // Remove the character 'e' if entered
        if (value.includes("e") || value.includes("E")) {
            event.target.value = value.replace(/[eE]/g, ""); // Remove 'e' or 'E'
        }

        // Prevent entering multiple decimal points
        if ((value.match(/\./g) || []).length > 1) {
            event.target.value = value.slice(0, -1); // Remove the last character if multiple decimal points are found
        }
    };

    const resetRates = () => {
        setRates([
            {
                rateName: "",
                rateValue: "",
                brand: "",
                condition: "",
                minPrice: "",
                maxPrice: "",
                minWeight: "",
                maxWeight: "",
                states: [],
                selectedBrands: [],
                selectedStates: []
            }
        ]);
        setErrors([{}]);
    };
    console.log("selectedInventoryLocations", selectedInventoryLocations)
    return (
        <Modal isOpen={isOpen} toggle={toggleModal} size="lg" onExit={toggleModal}>
            <ModalHeader className="bg-primary text-white" style={{ position: "relative" }}>
                <span className="text-white h3">Custom Rates</span>
                <button type="button" className="close-button" onClick={toggleModal}>
                    &times;
                </button>
            </ModalHeader>
            <ModalBody style={{ maxHeight: "90vh", overflowY: "auto" }}>
                <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
                    <Card className="shadow mt-3">
                        <CardHeader className="border-0 mb-0 pb-0">
                            <div className="customer-rate-flex-container">
                                <h3>Custom Rates</h3>
                            </div>
                        </CardHeader>
                        <CardBody>
                            {rates.map((rate, index) => (
                                <div key={index} style={{ position: "relative" }} ref={index === rates.length - 1 ? lastCardRef : null}>
                                    <Card className="mb-3">
                                        <CardBody>
                                            {/* Show the delete (FaTimes) icon on the first card only if there's more than one card */}
                                            {(index !== 0 || rates.length > 1) && (
                                                <FaTimes
                                                    style={{ position: "absolute", right: 10, top: 10, cursor: "pointer", color: "red", fontSize: "1.5em" }}
                                                    onClick={() => deleteRate(index)}
                                                />
                                            )}
                                            <Form>
                                                <FormGroup>
                                                    <Label for={`rateName-${index}`}>Rate Name *</Label>
                                                    <Input
                                                        type="text"
                                                        name={`rateName-${index}`}
                                                        innerRef={index === rates.length - 1 ? lastRateNameRef : null}
                                                        id={`rateName-${index}`}
                                                        value={rate.rateName}
                                                        onChange={e => handleInputChange(index, "rateName", e.target.value)}
                                                        required
                                                    />
                                                    {errors[index]?.rateName && <div className="text-danger">{errors[index].rateName}</div>}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for={`rateValue-${index}`}>Rate Value *</Label>
                                                    <Input
                                                        type="text"
                                                        name={`rateValue-${index}`}
                                                        id={`rateValue-${index}`}
                                                        value={rate.rateValue}
                                                        onChange={e => handleInputChange(index, "rateValue", e.target.value)}
                                                        onInput={handleInput}
                                                        required
                                                    />
                                                    {errors[index]?.rateValue && <div className="text-danger">{errors[index].rateValue}</div>}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for={`brand-${index}`}>Brand</Label>
                                                    <Select
                                                        options={brandValues}
                                                        isMulti
                                                        onChange={selectedOptions => handleBrandValue(index, selectedOptions)}
                                                        placeholder="Select one or multiple brand(s)"
                                                        value={rate.selectedBrands}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label for={`condition-${index}`}>Rate Condition *</Label>
                                                    <CustomInput
                                                        type="select"
                                                        id={`condition-${index}`}
                                                        value={rate.condition}
                                                        onChange={e => handleInputChange(index, "condition", e.target.value)}
                                                        required>
                                                        <option value="" disabled>
                                                            Select Condition
                                                        </option>
                                                        <option value="weight">Weight</option>
                                                        <option value="price">Price</option>
                                                    </CustomInput>
                                                    {rate.condition === "weight" ? (
                                                        <span>
                                                            <h5>Please make sure products have weight assigned in the product form</h5>{" "}
                                                        </span>
                                                    ) : null}
                                                    {errors[index]?.condition && <div className="text-danger">{errors[index].condition}</div>}
                                                </FormGroup>
                                                {rate.condition === "price" ? (
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for={`minPrice-${index}`}>Minimum Price</Label>
                                                                <Input
                                                                    ref={inputRef}
                                                                    type="number"
                                                                    name={`minPrice-${index}`}
                                                                    id={`minPrice-${index}`}
                                                                    min="0"
                                                                    value={rate.minPrice}
                                                                    onChange={e => handleInputChange(index, "minPrice", e.target.value)}
                                                                    onKeyDown={e => {
                                                                        if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    onPaste={e => {
                                                                        const pasteData = e.clipboardData.getData("Text");
                                                                        if (
                                                                            pasteData.includes("e") ||
                                                                            pasteData.includes("E") ||
                                                                            pasteData.includes("+") ||
                                                                            pasteData.includes("-")
                                                                        ) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    onInput={handleInput}
                                                                    onWheel={event => {
                                                                        event.target.blur();
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for={`maxPrice-${index}`}>Maximum Price</Label>
                                                                <Input
                                                                    ref={inputRef}
                                                                    type="number"
                                                                    name={`maxPrice-${index}`}
                                                                    id={`maxPrice-${index}`}
                                                                    min="0"
                                                                    value={rate.maxPrice}
                                                                    onChange={e => handleInputChange(index, "maxPrice", e.target.value)}
                                                                    onKeyDown={e => {
                                                                        if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    onPaste={e => {
                                                                        const pasteData = e.clipboardData.getData("Text");
                                                                        if (
                                                                            pasteData.includes("e") ||
                                                                            pasteData.includes("E") ||
                                                                            pasteData.includes("+") ||
                                                                            pasteData.includes("-")
                                                                        ) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    onInput={handleInput}
                                                                    onWheel={event => {
                                                                        event.target.blur();
                                                                    }}
                                                                />
                                                                {errors[index]?.maxPrice && <div className="text-danger">{errors[index].maxPrice}</div>}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={12}>
                                                            {errors[index]?.priceError && <div className="text-danger">{errors[index].priceError}</div>}
                                                        </Col>
                                                    </Row>
                                                ) : null}

                                                {rate.condition === "weight" ? (
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for={`minWeight-${index}`}>Minimum Weight</Label>
                                                                <Input
                                                                    ref={inputRef}
                                                                    type="number"
                                                                    name={`minWeight-${index}`}
                                                                    id={`minWeight-${index}`}
                                                                    min="0"
                                                                    value={rate.minWeight}
                                                                    onChange={e => handleInputChange(index, "minWeight", e.target.value)}
                                                                    onKeyDown={e => {
                                                                        if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    onPaste={e => {
                                                                        const pasteData = e.clipboardData.getData("Text");
                                                                        if (
                                                                            pasteData.includes("e") ||
                                                                            pasteData.includes("E") ||
                                                                            pasteData.includes("+") ||
                                                                            pasteData.includes("-")
                                                                        ) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    onInput={handleInput}
                                                                    onWheel={event => {
                                                                        event.target.blur();
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for={`maxWeight-${index}`}>Maximum Weight</Label>
                                                                <Input
                                                                    ref={inputRef}
                                                                    type="number"
                                                                    name={`maxWeight-${index}`}
                                                                    id={`maxWeight-${index}`}
                                                                    min="0"
                                                                    value={rate.maxWeight}
                                                                    onChange={e => handleInputChange(index, "maxWeight", e.target.value)}
                                                                    onKeyDown={e => {
                                                                        if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    onPaste={e => {
                                                                        const pasteData = e.clipboardData.getData("Text");
                                                                        if (
                                                                            pasteData.includes("e") ||
                                                                            pasteData.includes("E") ||
                                                                            pasteData.includes("+") ||
                                                                            pasteData.includes("-")
                                                                        ) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    onInput={handleInput}
                                                                    onWheel={event => {
                                                                        event.target.blur();
                                                                    }}
                                                                />
                                                                {errors[index]?.maxWeight && <div className="text-danger">{errors[index].maxWeight}</div>}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={12}>
                                                            {errors[index]?.weightError && <div className="text-danger">{errors[index].weightError}</div>}
                                                        </Col>
                                                    </Row>
                                                ) : null}

                                                <FormGroup>
                                                    <div>
                                                        <Label>State</Label>
                                                        <CustomMultiSelectDropdown
                                                            options={allOptions}
                                                            onChangeHandler={selectedOptions => handleChange(index, selectedOptions)}
                                                            placeholder="Select one or multiple state(s)"
                                                            values={rate.selectedStates}
                                                            isDisabled={false}
                                                            isValid={true}
                                                        />
                                                    </div>
                                                </FormGroup>
                                                <FormGroup>
                                                    <div>
                                                        <Label>Inventory locations</Label>
                                                        <Select
                                                            className="basic-single form-control m-0 p-0 border-0"
                                                            classNamePrefix="select"
                                                            placeholder="Select Inventory Location..."
                                                            isDisabled={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            isMulti={true}
                                                            name={`inventory-location-${index}`}
                                                            options={inventoryLocationList}
                                                            onChange={(value) => handleInventoryLocationChange(index, value)}
                                                            value={selectedInventoryLocations[index]}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                    {index !== rates.length - 1 && <hr style={{ borderTop: "2px solid #007bff" }} />}
                                </div>
                            ))}
                        </CardBody>
                    </Card>
                </div>
            </ModalBody>
            {showBanner && (
                <div
                    style={{
                        backgroundColor: "#2DCE89",
                        color: "white",
                        padding: "10px",
                        borderRadius: "5px",
                        fontWeight: "bold",
                        position: "fixed",
                        top: "20px", // Position it at the top of the screen
                        right: "20px", // Align it to the right
                        zIndex: "1050", // Ensure it appears above other elements
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)" // Optional: add some shadow for a pop-out effect
                    }}>
                    New Rate form has been added!
                </div>
            )}

            <ModalFooter style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                <Button color="primary" onClick={toggleModal}>
                    Close
                </Button>
                <div style={{ display: "flex", gap: "10px" }}>
                    <Button color="primary" onClick={addNewRate}>
                        Add Another Rate
                    </Button>
                    <Button color="success" onClick={handleSaveRate}>
                        Save Rates
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default StandardRates;
