/*
    Monday Task ID - 6322428226
    Migration of Bulk Order(Django Page) -> React
    Author - Akshay Vishwakarma
*/

import React, { useContext, useEffect, useState } from "react";
import { api } from "../../services/api";
import { ComponentContext } from "../../contexts/ComponentContext";
import { AuthContext } from "../../contexts/AuthContext";
import BadgePillFilter from "components/Filters/Badge";
import { Card, CardHeader, Col, Container, Row, Table, UncontrolledTooltip, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { FaTrash } from "react-icons/fa";
import Moment from "react-moment";
import { useLocation } from "react-router-dom";
import { addURLParam, processURLSearchParams, removeURLParam } from "../../utils/urls";
import PagePagination from "feature/pagination/Pagination";
import useMobileDetection from "customHooks/useMobileDetection";
import { mobileScreenThresholdValue } from "utils/constants";
import DeleteBulkOrderModel from "./DeleteBulkOrderModel";
import { useToasts } from "react-toast-notifications";
import "../../utils/commonStyles.css";

const WorkflowIcon = (workflow, id, text) => {
    const iconClassMap = {
        processing: "fas fa-clock text-info fa-2x",
        review: "fas fa-exclamation-circle text-primary fa-2x",
        error: "fas fa-times-circle text-danger fa-2x",
        invoice: "fas fa-file-invoice text-yellow fa-2x",
        ordering: "fas fa-receipt text-indigo fa-2x",
        order_under_review: "fas fa-check-circle text-orange fa-2x",
        done: "fas fa-check-circle text-success fa-2x"
    };

    const iconClass = iconClassMap[workflow] || "fas fa-question-circle text-secondary";

    return (
        <React.Fragment>
            <span>
                <i id={id} className={`${iconClass}`}></i>
            </span>
            <UncontrolledTooltip delay={0} placement="bottom" target={id}>
                {text ? text : "-"}
            </UncontrolledTooltip>
        </React.Fragment>
    );
};

const ProductListing = props => {
    const urlSearchParams = useLocation().search;
    const [BulkOrders, setBulkOrders] = useState([]);
    const [totalBulkOrders, setTotalBulkOrders] = useState(0);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({
        previous: null,
        next: null,
        pages: () => {
            return [];
        }
    });

    const [filter, setFilter] = useState({
        search: "",
        urlParamsProcessed: false
    });

    const [refresh, setRefresh] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { component } = useContext(ComponentContext);
    const { addToast } = useToasts();

    // Check weather screen size is mobile or desktop
    const isMobile = useMobileDetection(mobileScreenThresholdValue);

    const getParams = () => {
        let new_params = {
            page
        };

        if (filter.search?.length > 0) {
            new_params.search = filter.search;
        }
        return new_params;
    };

    React.useMemo(() => {
        processURLSearchParams(new URLSearchParams(urlSearchParams), filter, setFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlSearchParams]);

    useEffect(() => {
        if (filter.urlParamsProcessed) {
            setLoading(true);

            const new_params = getParams();

            api.get("/corporate/react/bulk-order-listing/", {
                params: new_params
            })
                .then(response => {
                    setBulkOrders(response.data.results);
                    setTotalBulkOrders(response.data.count);

                    const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
                    const previous = page !== 1 ? page - 1 : 1;
                    const next = page !== totalPages ? page + 1 : totalPages;
                    setPagination({
                        previous,
                        next,
                        pages: () => {
                            let startCount = 1;
                            let endCount = isMobile ? 4 : 12;
                            let numberCount = Math.round(endCount / 2);
                            const pageList = [];
                            if (numberCount < 0) numberCount = 1;
                            startCount = page - numberCount;

                            // If start count is negative or equal to zero
                            if (startCount <= 0) startCount = 1;
                            if (page !== 1) endCount = page + numberCount;
                            if (endCount > totalPages) endCount = totalPages;

                            if (totalPages >= endCount) {
                                for (let i = startCount; i <= endCount; i++) {
                                    pageList.push(i);
                                }
                            } else if (totalPages >= 1) {
                                for (let i = 1; i <= totalPages; i++) {
                                    pageList.push(i);
                                }
                            }
                            return pageList;
                        }
                    });
                    setLoading(false);
                    delete new_params["page"];
                    delete new_params["ordering"];
                })
                .catch(err => {
                    console.log(err);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filter, refresh, component]);

    const handlePagination = page => {
        setPage(page);
    };

    const handleRefresh = () => {
        setRefresh(!refresh);
    };

    let timer = null;
    const handleSearch = e => {
        setPage(1);
        addURLParam(window, "search", e.target.value);
        clearTimeout(timer);
        timer = setTimeout(function () {
            setFilter({
                ...filter,
                search: e.target.value
            });
        }, 750);
    };

    const removeBadge = e => {
        setPage(1);
        if (e.component === "search") {
            setFilter({ ...filter, search: null });
            removeURLParam(window, "search");
            document.getElementById("bulkOrderSeachInput").value = "";
        }
    };

    const { user, isAuthentication } = useContext(AuthContext);

    if (isAuthentication && !user.isProductFeature) {
        window.location.href = window.env.APP_URL;
    }

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [name, setName] = useState(false);
    const [id, setId] = useState(false);

    const toggle = id => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const handleDeleteBulkOrder = (id, name) => {
        setName(name);
        setId(id);
        setModalIsOpen(true);
    };

    const handleDelete = id => {
        setModalIsOpen(false);
        const payload = { disable: true };
        api.delete(`bulk-orders/delete/${id}/`, payload)
            .then(response => {
                ;
                setModalIsOpen(!modalIsOpen);
                setRefresh(!refresh);
                addToast(response?.data?.message, { appearance: "success", autoDismiss: true });
            })
            .catch(error => {
                console.log("Error deleting bulk order", error);
            });
    };

    return (
        <>
            {isAuthentication ? (
                <Container fluid>
                    <DeleteBulkOrderModel modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} onConfirmation={handleDelete} name={name} id={id} />
                    <div className={"pt-7"}>
                        {isAuthentication && (
                            <Row className={"mb-2"}>
                                <Col xs={10} lg={"2"} className={"text-left"}>
                                    <input
                                        type="text"
                                        className={"button-font-size form-control form-control-sm"}
                                        placeholder={"Bulk Order Title"}
                                        defaultValue={filter.search}
                                        onChange={handleSearch}
                                        newparams={""}
                                        id="bulkOrderSeachInput"
                                    />
                                </Col>
                                <Col xs={2} lg={"10"} className={"text-right"}>
                                    <div className={"d-none d-lg-inline-block mr-2"}>
                                        <button className="button-font-size btn btn-neutral btn-sm p-9px" onClick={handleRefresh}>
                                            <i className="fas fa-sync-alt"></i>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            {isAuthentication && (
                                <div className="col">
                                    <Card className="shadow ">
                                        <CardHeader className="border-0">
                                            <Row>
                                                <Col>
                                                    <h3 className="mb-0">
                                                        Bulk Orders <small>({totalBulkOrders})</small>
                                                    </h3>
                                                </Col>
                                                <Col className="text-right">
                                                    <a href={window.env.APP_URL + "corporate/bulk-order/create/"} className="button-font-size btn btn-success btn-sm">
                                                        <i className="fas fa-plus"></i> Create New Order
                                                    </a>
                                                </Col>

                                                <Col sm="12">
                                                    <BadgePillFilter title="bulk order" data={filter} onClick={removeBadge} />
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        {isLoading ? (
                                            <div className="text-center mt-3 mb-3">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <Table className="align-items-center table-flush" responsive>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col" className="pl-3 pr-1">
                                                                Title
                                                            </th>
                                                            <th>Email</th>

                                                            <th scope="col" className="pl-3 pr-3">
                                                                Created At
                                                            </th>

                                                            <th scope="col" className="pl-3 pr-3">
                                                                Status
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Track Order
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3">
                                                                Message
                                                            </th>
                                                            <th scope="col" className="pl-3 pr-3"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {BulkOrders.map(bulkOrderData => (
                                                            <tr key={bulkOrderData.id}>
                                                                <td className="pl-3 pr-3">
                                                                    <a href={`/bulk-order-details?${new URLSearchParams({ bulkOrderId: `${bulkOrderData.id}` })}`}>
                                                                        {bulkOrderData.title}
                                                                    </a>
                                                                </td>
                                                                <td className="pl-3 pr-3">{bulkOrderData.email}</td>
                                                                <td className="pl-3 pr-3">
                                                                    <Moment format="MM/D/YYYY" withTitle>
                                                                        {bulkOrderData.created_at}
                                                                    </Moment>
                                                                </td>

                                                                <td className="pl-3 pr-3">
                                                                    {bulkOrderData.workflow ? (
                                                                        <div style={{ cursor: "pointer" }}>
                                                                            {" "}
                                                                            {WorkflowIcon(
                                                                                bulkOrderData.workflow,
                                                                                String(bulkOrderData.workflow + bulkOrderData.id),
                                                                                bulkOrderData.get_workflow_display
                                                                            )}
                                                                        </div>
                                                                    ) : (
                                                                        <span>None</span>
                                                                    )}
                                                                </td>

                                                                <td className="pl-3 pr-3">
                                                                    {bulkOrderData.get_react_order_status_page_tracking_url ? (
                                                                        <a href={bulkOrderData.get_react_order_status_page_tracking_url}>Track Order</a>
                                                                    ) : (
                                                                        <span>Not available</span>
                                                                    )}
                                                                </td>
                                                                <td className="pl-3 pr-3">
                                                                    {bulkOrderData.get_workflow_display ? (
                                                                        <span>{bulkOrderData.get_workflow_display}</span>
                                                                    ) : (
                                                                        <span> - </span>
                                                                    )}
                                                                </td>
                                                                <td className="pl-3 pr-3">
                                                                    {bulkOrderData && bulkOrderData.workflow !== "done" && (
                                                                        <Dropdown
                                                                            isOpen={dropdownOpen[bulkOrderData.id] || false}
                                                                            toggle={() => toggle(bulkOrderData.id)}>
                                                                            <DropdownToggle
                                                                                className="btn text-primary"
                                                                                role="button"
                                                                                size="sm"
                                                                                color="white"
                                                                                onClick={e => e.preventDefault()}>
                                                                                <i className="fas fa-ellipsis-v text-dark"></i>
                                                                            </DropdownToggle>
                                                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                                                <DropdownItem
                                                                                    onClick={() =>
                                                                                        handleDeleteBulkOrder(bulkOrderData.id, bulkOrderData.title)
                                                                                    }>
                                                                                    <FaTrash size={20} color="red" /> Delete
                                                                                </DropdownItem>
                                                                            </DropdownMenu>
                                                                        </Dropdown>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <PagePagination
                                                    pagination={pagination}
                                                    handlePagination={handlePagination}
                                                    page={page}
                                                    totalCount={totalBulkOrders}
                                                />
                                            </>
                                        )}
                                    </Card>
                                </div>
                            )}
                        </Row>
                    </div>
                </Container>
            ) : null}
        </>
    );
};

export default ProductListing;
