import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledDropdown,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import { Fulfillment, BillingStatusColor, FinancialStatus } from '../../components/Order';
import { api } from 'services/api';
import { AuthContext } from 'contexts/AuthContext';
import { ComponentContext } from '../../contexts/ComponentContext';
import Filter from '../../components/Filters/Filter';
import { ChargebackStatusModal } from '../../components/ShippingChargebacks';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import fileDownload from 'js-file-download';
import DateRange from '../../components/Filters/DateRange';
import BadgePillFilter from '../../components/Filters/Badge';
import Moment from 'react-moment';
import { getParams } from 'utils/commonFunctions';
import PagePagination from "../../feature/pagination/Pagination";
import useMobileDetection from 'customHooks/useMobileDetection';
import { mobileScreenThresholdValue } from 'utils/constants';
import { Sort, handleSort } from "../../utils/commonFunctions";
import { addURLParam, processURLSearchParams, removeAllURLParams, removeURLParam } from "../../utils/urls";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { prefixAndFilterMapping } from 'customHooks/useFilter';

const ShippingChargebacks = (props) => {
  const [chargebacks, setChargeback] = useState([]);
  const [chargebackCount, setChargebackCount] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    previous: null,
    next: null,
    pages: () => {
      return [];
    },
  });

  const [sort, setSort] = useState('-id');
  const [sortOrder, setSortOrder] = useState({order: "", columnName: ""});
  const [isLoading, setLoading] = useState(false);
  const [isSync, setSync] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState([]);
  const [params, setParams] = useState([]);
  const [clearall, setClearAll] = useState(false);
  const [status, setStatus] = useState([]);
  const [totalChargebackAmount, setTotalChargebackAmount] = useState([]);
  const [filter, setFilter] = useState({
    startDate: null,
    endDate: null,
    shippingChargebackBrands: [],
    billingStatuss: [],
    channels: [],
    typeDiscounts: [],
    invoiceNumbers: [],
    order_id: [],
    chargeBackStartDate:null,
    ChargeBackEndDate:null,
    //6509739352 - Store Filter on Shipping and discount code chargebacks
    store: [],
    //7630687965 - Assignee Filter on Shipping and discount code chargebacks
    assignees: [],
  });
  const [openModal, setOpenModal] = useState({
    updateChargebackRecords: false,
  });
  const [orderId, setOrderId] = useState();
  const [exportButton, setExportButton] = useState({
    label: 'Export',
    disabled: false,
  });
  const [modalType, setModalType] = useState(null);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);

  const cancelToken = React.useRef(undefined);

  const [selectAllShippingChargebacksInThisView, setselectAllShippingChargebacksInThisView] =
    useState(false);
  const { addToast } = useToasts();

  // Check weather screen size is mobile or desktop
  const isMobile = useMobileDetection(mobileScreenThresholdValue);

  const handleCheckbox = (e, brand_id) => {
    let checkbox = [...new Set(selected)];

    if (e.target.checked) {
      checkbox.push({ order_id: e.target.value, title: e.target.name, brand_id: brand_id });
    } else {
      // !== strict inequality operator checking the order id available or not, so that's why we use != inequality operator
      checkbox = checkbox.filter((item) => item.order_id != e.target.value);
    }
    setClearAll(checkbox.length);
    setSelected(checkbox);
  };

  const handleDateRange = (start, end) => {
    setPage(1);
    addURLParam(window, "startDate", start);
    addURLParam(window, "endDate", end);
    setFilter({
      ...filter,
      startDate: start,
      endDate: end,
    });
  };

  const handleCreatedDateRange = (start, end) => {
    setPage(1);
    addURLParam(window, "chargeBackStartDate", start);
    addURLParam(window, "ChargeBackEndDate", end);
    setFilter({
      ...filter,
      chargeBackStartDate: start,
      ChargeBackEndDate: end,
    });

  };

  const handleExportChargebacksOrder = () => {
    setExportButton({
      label: 'Exporting...',
      disabled: true,
    });

    let recordParams = params;
    recordParams.chargeback_export_ids = selected.map((item) => item.order_id);

    api
      .get('chargeback/export/', {
        params: recordParams,
      })
      .then((response) => response.data)
      .then((response) => {
        const success = response?.success | false;

        if (success) {
          addToast(response.message, {
            appearance: 'success',
            autoDismiss: false,
          });
        } else {
          const timestamp = moment().format('MMMM-Do-YYYY-h:mm:ss-a');
          const filename = 'billing_chargebacks_' + timestamp + '.csv';
          fileDownload(response, filename);
        }

        setExportButton({
          label: 'Export',
          disabled: false,
        });
      });
  };

  useEffect(() => {
    api
      .get('/order/shipping-status/')
      .then((response) => {
        setStatus(response.data.results);
      })
      .catch((error) => console.log(error));
  }, []);

  const { component } = useContext(ComponentContext);

  useEffect(() => {
    setLoading(true);
    let new_params = getParams(filter);
    if (typeof cancelToken.current != undefined) {
      cancelToken.current !== undefined && cancelToken.current.cancel("Operation canceled due to new request.");
    }
    cancelToken.current = axios.CancelToken.source();
    new_params = {
      ...new_params,
      page,
      ordering: sort,
    };
    api
      .get('order/shipping-chargebacks/', {
        params: new_params,
        cancelToken: cancelToken.current.token

      })
      .then((response) => {
        setChargeback(response.data.results);
        setChargebackCount(response.data.count);

        const totalPages = Math.ceil(response.data.count / window.env.PAGE_SIZE);
        const previous = page !== 1 ? page - 1 : 1;
        const next = page !== totalPages ? page + 1 : totalPages;
        setPagination({
          previous,
          next,
          pages: () => {
            let startCount = 1;
            let endCount = isMobile ? 4 : 12;
            let numberCount = Math.round(endCount / 2);
            const pageList = [];
            if (numberCount < 0) numberCount = 1;
            startCount = page - numberCount;
            if (startCount <= 0) startCount = 1;
            if (page !== 1) endCount = page + numberCount;
            if (endCount > totalPages) endCount = totalPages;
            if (totalPages >= endCount) {
              for (let i = startCount; i <= endCount; i++) {
                pageList.push(i);
              }
            } else if (totalPages >= 1) {
              for (let i = 1; i <= totalPages; i++) {
                pageList.push(i);
              }
            }
            return pageList;
          },
        });
        setLoading(false);
        delete new_params['page'];
        delete new_params['ordering'];
        setParams(new_params);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, sort, filter, component, refresh]);

  let timer = null;
  const handleSearch = (e) => {
    setPage(1);
    addURLParam(window, "search", e.target.value);
    clearTimeout(timer);
      timer = setTimeout(function () {
          setFilter({
              ...filter,
              search: e.target.value,
          });
      }, 750);
  };

  //API call to get the total shipping chargeback amount
  useEffect(() => {
    let new_params = getParams(filter);
    new_params = {
      ...new_params,
      page,
      ordering: sort,
    };
    api
      .get('order/total-chargeback-amount/', {
        params: new_params,
      })
      .then((response) => {
        setTotalChargebackAmount(response.data.results);
        setParams(new_params);
      })
      .catch((error) => addToast(error.message, { appearance: 'error' }));
  }, [filter, selected, totalChargebackAmount]);

  const handlePagination = (page) => {
    setPage(page);
  };

  const removeBadge = (e) => {
    setPage(1);
    if (e.component === 'date') {
      setFilter({ ...filter, startDate: null, endDate: null });
      removeURLParam(window, "startDate");
      removeURLParam(window, "endDate");
    }  else if (e.component === 'chargeBackDate') {
      setFilter({ ...filter, chargeBackStartDate: null, ChargeBackEndDate: null });
      removeURLParam(window, "chargeBackStartDate");
      removeURLParam(window, "ChargeBackEndDate");
    }  else if (e.component === 'brand') {
      let items = filter.shippingChargebackBrands;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, shippingChargebackBrands: items });
      removeURLParam(window, "shippingChargebackBrands", e.item.value);
    } else if (e.component === "search") {
      setFilter({ ...filter, search: null });
      document.getElementById("discountCodeChargebackSearch").value = "";
      removeURLParam(window, "search");
    } else if (e.component === 'status') {
      let items = filter.billingStatuss;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, billingStatuss: items });
      removeURLParam(window, "billingStatuss", e.item.value);
    } else if (e.component === 'sales_channel') {
      let items = filter.channels;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, channels: items });
      removeURLParam(window, "channels", e.item.value);
    } else if (e.component === 'typeDiscount') {
      let items = filter.typeDiscounts;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, typeDiscounts: items });
      removeURLParam(window, "typeDiscounts", e.item.value);
    } else if (e.component === 'invoiceNumbers') {
      let items = filter.invoiceNumbers;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, invoiceNumbers: items });
      removeURLParam(window, "invoiceNumbers", e.item.value);
    }
    //6509739352 - Store Filter on Shipping and discount code chargebacks
     else if (e.component === 'store') {
      let items = filter.store;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, store: items });
      removeURLParam(window, "store", e.item.value);
    }
     //7630687965 - Assignee Filter on Shipping and discount code chargebacks
     else if (e.component === 'assignee') {
      let items = filter.assignees;
      items = items.filter((item) => item.value !== e.item.value);
      setFilter({ ...filter, assignees: items });
      removeURLParam(window, "assignees", e.item.value);
    }
    if (e.component === 'selected') {
      let items = selected;
      items = items.filter((item) => item.id !== e.item.id);

      if (e.component === 'date') {
        setFilter({ ...filter, startDate: null, endDate: null });
      }else if (e.component === 'chargeBackDate') {
        setFilter({ ...filter, chargeBackStartDate: null, ChargeBackEndDate: null });
      }  else if (e.component === 'billingStatuss') {
        let items = filter.billingStatuss;
        items = items.filter((item) => item.value !== e.item.value);
        setFilter({ ...filter, billingStatuss: items });
      } else if (e.component === 'brand') {
        let items = filter.shippingChargebackBrands;
        items = items.filter((item) => item.value !== e.item.value);
        setFilter({ ...filter, shippingChargebackBrands: items });
      } else if (e.component === 'channel') {
        let items = filter.channels;
        items = items.filter((item) => item.value !== e.item.value);
        setFilter({ ...filter, channels: items });
      } else if (e.component === 'typeDiscount') {
        let items = filter.typeDiscounts;
        items = items.filter((item) => item.value !== e.item.value);
        setFilter({ ...filter, typeDiscounts: items });
      } else if (e.component === 'invoiceNumbers') {
        let items = filter.invoiceNumbers;
        items = items.filter((item) => item.value !== e.item.value);
        setFilter({ ...filter, invoiceNumbers: items });
      }
      //6509739352 - Store Filter on Shipping and discount code chargebacks
        else if (e.component === 'store') {
        let items = filter.store;
        items = items.filter((item) => item.value !== e.item.value);
        setFilter({ ...filter, store: items });
      }
      if (e.component === 'selected') {
        let items = selected;
        items = items.filter((item) => item.id !== e.item.id);

        setSelected(items);
        let checkbox = document.getElementById('checkbox_' + e.item.id);
        if (checkbox) {
          checkbox.checked = false;
        }
      }
      setClearAll(false);
    }
    setClearAll(false);
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const { user, isAuthentication } = useContext(AuthContext);

  const handleBrands = (e,prefix) => {
    setPage(1);
    let checkbox = [...new Set(filter.shippingChargebackBrands)];

    if (e.target.checked) {
      checkbox.push({ value: e.target.value, label: e.target.dataset.label });
      addURLParam(window, prefixAndFilterMapping[prefix], e.target.value);
    } else {
      checkbox = checkbox.filter((item) => item.value !== e.target.value);
      removeURLParam(window, "shippingChargebackBrands", e.target.value);
    }

    setFilter({ ...filter, shippingChargebackBrands: checkbox });
  };

  const handleBillingStatus = (e,prefix) => {
    setPage(1);
    if (e == "__all__") {
      let billingStatuss = [];
      let checkbox = document.querySelectorAll(".checkbox_billingStatus");
      for (let i = 0; i < checkbox.length; i++) {
          checkbox[i].checked = true;
          billingStatuss.push({
              value: checkbox[i].value,
              label: checkbox[i].dataset.label,
          });
      }
      setFilter({ ...filter, billingStatuss: billingStatuss });
  } else {
      let checkbox = [...new Set(filter.billingStatuss)];

      if (e.target.checked) {
        checkbox.push({ value: e.target.value, label: e.target.dataset.label });
        addURLParam(window, prefix, e.target.value);
      } else {
        checkbox = checkbox.filter((item) => item.value !== e.target.value);
        removeURLParam(window, prefix, e.target.value);
      }

      setFilter({ ...filter, billingStatuss: checkbox });
    }
  };

  const handleChannel = (e,prefix) => {
    setPage(1);
    if (e == "__all__") {
      let channels = [];
      let checkbox = document.querySelectorAll(".checkbox_sales_channel");
      for (let i = 0; i < checkbox.length; i++) {
          checkbox[i].checked = true;
          channels.push({
              value: checkbox[i].value,
              label: checkbox[i].dataset.label,
          });
      }
      setFilter({ ...filter, channels: channels });
  } else {
      let checkbox = [...new Set(filter.channels)];
      if (e.target.checked) {
        checkbox.push({ value: e.target.value, label: e.target.dataset.label });
        addURLParam(window, prefix, e.target.value);
      } else {
        checkbox = checkbox.filter((item) => item.value !== e.target.value);
        removeURLParam(window, "channels", e.target.value);
      }
      setFilter({ ...filter, channels: checkbox });
    }
  };

  const handleTypeDiscount = (e,prefix) => {
    setPage(1);
    /* Handling check & uncheck of discount type filter in a list*/
    if (e == "__all__") {
      let typeDiscounts = [];
      let checkbox = document.querySelectorAll(".checkbox_typeDiscount");
      for (let i = 0; i < checkbox.length; i++) {
          checkbox[i].checked = true;
          typeDiscounts.push({
              value: checkbox[i].value,
              label: checkbox[i].dataset.label,
          });
      }
      setFilter({ ...filter, typeDiscounts: typeDiscounts });
  } else {
      let checkbox = [...new Set(filter.typeDiscounts)];
      if (e.target.checked) {
        checkbox.push({ value: e.target.value, label: e.target.dataset.label });
        addURLParam(window, prefix, e.target.value);
      } else {
        checkbox = checkbox.filter((item) => item.value !== e.target.value);
        removeURLParam(window, "typeDiscounts", e.target.value);
      }
      setFilter({ ...filter, typeDiscounts: checkbox });
    }
  };

  const handleInvoiceNumber = (e, prefix) => {
    setPage(1);
    /* Handling check & uncheck of invoice number filter in a list*/
    let checkbox = [...new Set(filter.invoiceNumbers)];

    if (e.target.checked) {
      checkbox.push({ value: e.target.value, label: e.target.dataset.label });
      addURLParam(window, prefix, e.target.value);
    } else {
      checkbox = checkbox.filter((item) => item.value !== e.target.value);
      removeURLParam(window, prefix, e.target.value);
    }

    setFilter({ ...filter, invoiceNumbers: checkbox });
  };
  //6509739352 - Store Filter on Shipping and discount code chargebacks
  const handleStore = (e, prefix) => {
    setPage(1);
    /* Handling check & uncheck of store filter in a list*/
    let checkbox = [...new Set(filter.store)];

    if (e.target.checked) {
      checkbox.push({ value: e.target.value, label: e.target.dataset.label });
      addURLParam(window, prefix, e.target.value);
    } else {
      checkbox = checkbox.filter((item) => item.value !== e.target.value);
      removeURLParam(window, prefix, e.target.value);
    }

    setFilter({ ...filter, store: checkbox });
  };

  //7630687965 - Assignee Filter on Shipping and discount code chargebacks
  const handleAssignee = (e, prefix) => {
    let checkbox = [...new Set(filter.assignees)];

    if (e.target.checked) {
      checkbox.push({ value: e.target.value, label: e.target.dataset.label });
      addURLParam(window, prefix, e.target.value);
    } else {
      checkbox = checkbox.filter((item) => item.value !== e.target.value);
      removeURLParam(window, prefix, e.target.value);
    }

    setFilter({ ...filter, assignees: checkbox });
  };

  const refChargebacksCheckbox = useRef(false);
  const handleChargebacksCheckbox = (e) => {
    refChargebacksCheckbox.current = e.target.checked;
    let checkboxes = document.querySelectorAll('.chargebackCheckbox');
    let items = [];
    setClearAll(checkboxes.length);
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = e.target.checked;
      if (e.target.checked === true) {
        items.push({
          order_id: parseInt(checkboxes[i].value),
          title: checkboxes[i].name,
          email: checkboxes[i].email,
        });
      }
    }
    setSelected(items);
  };

  const handleClearAll = () => {
    setPage(1);
    setClearAll(false);
    setSelected([]);

    setFilter({
      startDate: null,
      endDate: null,
      shippingChargebackBrands: [],
      billingStatuss: [],
      channels: [],
      typeDiscounts: [],
      invoiceNumbers: [],
      chargeBackStartDate:null,
      ChargeBackEndDate:null
    });
    removeAllURLParams();
  };

  const clearFilter = (prefix) => {
    setPage(1);
    if (prefix === 'shippingChargebackBrands') {
      setFilter({ ...filter, shippingChargebackBrands: [] });
      removeURLParam(window, prefixAndFilterMapping[prefix]);
    } else if (prefix === 'billingStatuss') {
      setFilter({ ...filter, billingStatuss: [] });
      setClearAll(false);
      removeURLParam(window, prefixAndFilterMapping[prefix]);
    } else if (prefix === 'typeDiscount') {
      setFilter({ ...filter, typeDiscounts: [] });
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setClearAll(false);
    } else if (prefix === 'channels') {
      setFilter({ ...filter, channels: [] });
      removeURLParam(window, prefixAndFilterMapping[prefix]);
      setClearAll(false);
    } else if (prefix === 'invoiceNumbers') {
      setFilter({ ...filter, invoiceNumbers: [] });
      setClearAll(false);
      removeURLParam(window, prefixAndFilterMapping[prefix]);
    }
      else if (prefix === 'store') {
      setFilter({ ...filter, store: [] });
      setClearAll(false);
      removeURLParam(window, prefixAndFilterMapping[prefix]);
    }
      else if (prefix === 'assignee') {
      setFilter({ ...filter, assignees: [] });
      setClearAll(false);
      removeURLParam(window, prefixAndFilterMapping[prefix]);
    }
    let checkbox = document.querySelectorAll('.checkbox_' + prefix + ':checked');
    if (checkbox.length > 0) {
      for (let i = 0; i < checkbox.length; i++) {
        checkbox[i].checked = false;
      }
    }
  };

  if (isAuthentication && !user.isSupplier && !user.isAdmin) {
    window.location.href = window.env.APP_URL;
  }

  const handleSync = () => {
    setSync(true);
    api.post('order/shipping-chargebacks/').then((response) => {
      setSync(false);
      addToast('Data synced.', { appearance: 'success', autoDismiss: true });
    });
  };

  /**
   * Handler to open update chargeback status
   * @param {*} updateChargebackRecords
   */
  const handleUpdateChargebackStatusModal = (updateChargebackRecords) => {
    setOrderId(updateChargebackRecords);
    if (selected.length === 1) {
      setOpenModal({ updateChargebackRecords: true });
    }
    else if (modalType === 'ChargebackStatusModal' && selected.length > 1) {
      setOpenModal({ updateChargebackRecords: true });
    }
    else if (modalType === 'ErrorModal') {
      setOpenArchiveModal(!openArchiveModal);
    }
  };

  /**
   * Clearing all the selected orders
   * @param {*}
   */
  const onCompleteStatusUpdate = () => {
    setSelected('');
  };

  const urlSearchParams = useLocation().search;
  React.useMemo(() => {
    if (filter.urlParamsProcessed === undefined || filter.urlParamsProcessed === null || filter.urlParamsProcessed === undefined) {
      processURLSearchParams(new URLSearchParams(urlSearchParams), filter, setFilter)
    };
  }, [urlSearchParams]);
  useEffect(() => {
    if (selected.length === 1) {
      // Case 1: Single record selected
      setModalType('ChargebackStatusModal');
    } else if (selected.length > 1) {
      // Case 2: Multiple records selected
      const areBrandIdsSame = selected.every(order => order.brand_id === selected[0].brand_id);
  
      // Set modalType based on brand_id check
      setModalType(areBrandIdsSame ? 'ChargebackStatusModal' : 'ErrorModal');
    }
  }, [selected]);
  
  const toggleArchiveModal = () => {
    setOpenArchiveModal(!openArchiveModal);
}
  return (
    <>
      {isAuthentication ? (
        <Container fluid>
        {modalType === 'ChargebackStatusModal' && selected.length > 1 &&(
          <ChargebackStatusModal
            orders={orderId}
            onSubmitURL="order/update/chargeback-status"
            selectAllShippingChargebacksInThisView={selectAllShippingChargebacksInThisView}
            filter={filter}
            onComplete={onCompleteStatusUpdate}
            isOpen={openModal.updateChargebackRecords}
            shippingChargebacksCount={chargebackCount}
            onExit={() =>
              setOpenModal({ updateChargebackRecords: !openModal.updateChargebackRecords })
            }
          />
        )}
        {(modalType === 'ChargebackStatusModal' && selected.length === 1) &&(
          <ChargebackStatusModal
            orders={orderId}
            onSubmitURL="order/update/chargeback-status"
            selectAllShippingChargebacksInThisView={selectAllShippingChargebacksInThisView}
            filter={filter}
            onComplete={onCompleteStatusUpdate}
            isOpen={openModal.updateChargebackRecords}
            shippingChargebacksCount={chargebackCount}
            onExit={() =>
              setOpenModal({ updateChargebackRecords: !openModal.updateChargebackRecords })
            }
          />
        )}
          {modalType === 'ErrorModal' && (
            <Modal size="lg" style={{ maxHeight: '450px' }} isOpen={openArchiveModal} toggle={toggleArchiveModal}>
              <ModalHeader>
                <h2 style={{ color: "red" }}>
                  {`Error`}
                </h2>
              </ModalHeader>
              <ModalBody>
                <div style={{ color: "red", fontSize: 18 }}>
                  {`“Please select orders that belong to the same brand”.`}
                </div>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={toggleArchiveModal} style={{ marginLeft: 300 }}>
                  <i class="fas fa-times"></i> Close
                </button>
              </ModalBody>
            </Modal>
          )}
          <div className={'pt-7'}>
            {isAuthentication && (
              <Row className={'mb-2'}>
                <Col xs={8} lg={2} className={"text-left"}>
                  {/* 7278215305 - increase font size */}
                  <input
                    type="text"
                    className={"button-font-size form-control form-control-sm"}
                    placeholder={"Search Order ID"}
                    id="discountCodeChargebackSearch"
                    defaultValue={filter.search}
                    onChange={handleSearch}
                    newparams={""}
                  />
                </Col>
                <Col className={'text-right'}>
                  <div className={'d-none d-lg-inline-block mr-2'}>
                    {/* 7278215305 - increase font size */}
                    <button className="button-font-size btn btn-neutral btn-sm p-9px" onClick={handleRefresh}>
                      <i className="fas fa-sync-alt"></i>
                    </button>
                  </div>
                  <Filter
                    name={'Brand'}
                    prefix={'shippingChargebackBrands'}
                    onChange={handleBrands}
                    newparams={params}
                    selected={filter.shippingChargebackBrands}
                    url={'/order/filter/brand/'}
                    onClear={() => clearFilter('shippingChargebackBrands')}
                  />

                  <Filter
                    name={'Billing Status'}
                    prefix={'billingStatuss'}
                    onChange={handleBillingStatus}
                    newparams={params}
                    selected={filter.billingStatuss}
                    inputSearch={false}
                    url={'order/shipping-status/'}
                    onClear={() => clearFilter('billingStatuss')}
                  />

                  <Filter
                    name={'Sales Channel'}
                    prefix={'channels'}
                    onChange={handleChannel}
                    newparams={params}
                    selected={filter.channels}
                    url={'order/filter/channel/'}
                    onClear={() => clearFilter('channels')}
                  />

                  {user?.isAdmin &&
                    <Filter
                      name={'Discount Type'}
                      prefix={'typeDiscounts'}
                      onChange={handleTypeDiscount}
                      newparams={params}
                      selected={filter.typeDiscounts}
                      url={'discount/filter/discount-code-type/'}
                      onClear={() => clearFilter('typeDiscount')}
                    />
                  }

                  <Filter
                    name={'Invoice Number'}
                    prefix={'invoiceNumbers'}
                    onChange={handleInvoiceNumber}
                    newparams={params}
                    selected={filter.invoiceNumbers}
                    url={'/order/filter/invoice-number/'}
                    onClear={() => clearFilter('invoiceNumbers')}
                  />

                  <Filter
                    name={'Store'}
                    prefix={'store'}
                    onChange={handleStore}
                    newparams={params}
                    selected={filter.store}
                    url={'order/shipping-chargebacks/filter/store'}
                    onClear={() => clearFilter('store')}
                  />
                  
                  {/* 7630687965 - Assignee Filter on Shipping and discount code chargebacks */}
                  <Filter
                    name={'Assignee'}
                    prefix={'assignees'}
                    onChange={handleAssignee}
                    newparams={params}
                    selected={filter.assignees}
                    url={'order/chargebacks/filter/assignee'}
                    onClear={() => clearFilter('assignee')}
                  />

                  <DateRange onCallback={handleDateRange} button_name={'Order Date'}></DateRange>
                  <DateRange onCallback={handleCreatedDateRange} button_name={'Chargeback Created Date'}></DateRange>
                  {/* 7278215305 - increase font size */}
                  <Button
                    color={'primary'}
                    size={'sm'}
                    className="button-font-size p-8px"
                    disabled={exportButton.disabled}
                    onClick={handleExportChargebacksOrder}
                  >
                    <i className="fas fa-file-csv"></i> {exportButton.label}
                  </Button>
                  {user.isAdmin && (
                    <>
                      {/* 7278215305 - increase font size */}
                      <Button className="button-font-size p-8px" type={'submit'} size={'sm'} color={'primary'} onClick={handleSync} disabled={isSync}>
                        Sync Now
                        {isSync && <Spinner size="sm" color="light" />}
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            )}
            <Row>
              {isAuthentication && (
                <div className="col">
                  <Card className="shadow ">
                    <CardHeader className="border-0">
                      <Row className="d-flex justify-content-between align-items-center p-1">
                        <div className="d-flex">
                          <h3 className="mb-0">
                            Shipping Chargebacks <small>({chargebackCount})</small>
                          </h3>

                          {clearall > 0 && (
                            <a
                              href={void 0}
                              className="badge badge-pill badge-secondary bg-primary text-white filter-pill"
                              onClick={handleClearAll}
                              id="clear_all_filters"
                            >
                              <i className="fas fa-times-circle"></i> Clear All filters
                            </a>
                          )}

                          {selected.length === 50 && (
                            <Button
                              className="ml-2 bg-transparent border-none shadow-none text-primary"
                              onClick={() => {
                                setselectAllShippingChargebacksInThisView(
                                  !selectAllShippingChargebacksInThisView
                                );
                              }}
                              size="sm"
                            >
                             <u> {!selectAllShippingChargebacksInThisView
                                ? ' Select all Shipping chargebacks in this view '
                                : ' All Shipping chargebacks in this view are selected '} </u>
                            </Button>
                          )}
                        </div>
                        <div className="d-flex align-items-center">
                          {selected.length > 0 && user.isAdmin && (
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn text-primary"
                                href="#"
                                role="button"
                                size="sm"
                                color="white"
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-cogs" /> Actions
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  href="#"
                                  onClick={(e) => handleUpdateChargebackStatusModal(selected)}
                                >
                                  <i className="fas fa-envelope" /> Update Chargeback Status
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          )}
                          <h5 className="mb-0">
                            Total Chargeback Amount: ${totalChargebackAmount}
                          </h5>
                        </div>
                      </Row>

                      <Row>
                        <Col sm="12">
                          <BadgePillFilter
                            data={filter}
                            selected={selected}
                            onClick={removeBadge}
                          />
                        </Col>
                      </Row>
                    </CardHeader>
                    {isLoading ? (
                      <div className="text-center mt-3 mb-3">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <Table className="align-items-center table-flush" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col" className="pl-3 pr-3">
                                <input
                                  type="checkbox"
                                  name={'check_chargebacks'}
                                  id={'checkbox_chargebacks'}
                                  ref={refChargebacksCheckbox}
                                  defaultChecked={refChargebacksCheckbox.current}
                                  onChange={handleChargebacksCheckbox}
                                />
                              </th>
                              <th scope="col" className="pl-1 pr-1">
                                Brand Name
                              </th>
                              <th scope="col" className="pl-1 pr-1">
                                <Sort onClick={() => handleSort(sort, 'order__shopify_id', setSortOrder, setSort)} sortingOrder={sortOrder} column="order__shopify_id">
                                  Order ID
                                </Sort>
                              </th>
                              <th scope="col" className="pl-1 pr-1">
                                <Sort onClick={() => handleSort(sort, 'order__created_at', setSortOrder, setSort)} sortingOrder={sortOrder} column="order__created_at">
                                  Order created_at
                                </Sort>
                              </th>
                              <th scope="col" className="pl-1 pr-1">
                              <Sort onClick={() => handleSort(sort, 'created_at', setSortOrder, setSort)} sortingOrder={sortOrder} column="created_at">
                                  Chargeback created at
                              </Sort>
                              </th>
                              <th scope="col" className="pl-1 pr-1">
                                Assignee
                              </th>
                              <th scope="col" className="pl-1 pr-1">
                                <Sort onClick={() => handleSort(sort, 'shipping_cost', setSortOrder, setSort)} sortingOrder={sortOrder} column="shipping_cost">
                                  Shipping Cost
                                </Sort>
                              </th>
                              <th scope="col" className="pl-1 pr-1">
                                <Sort onClick={() => handleSort(sort, 'shipping_paid', setSortOrder, setSort)} sortingOrder={sortOrder} column="shipping_paid">
                                  Shipping Paid
                                </Sort>
                              </th>
                              <th scope="col" className="pl-1 pr-1">
                                <Sort onClick={() => handleSort(sort, 'chargeback_amount', setSortOrder, setSort)} sortingOrder={sortOrder} column="chargeback_amount">
                                  Chargeback Amount
                                </Sort>
                              </th>
                              {user?.isAdmin &&
                                <th scope="col" className="pl-1 pr-1">
                                  <Sort onClick={() => handleSort(sort, 'order__discount_code', setSortOrder, setSort)} sortingOrder={sortOrder} column="order__discount_code">
                                    Discount Code
                                  </Sort>
                                </th>
                              }
                              {user?.isAdmin &&
                                <th scope="col" className="pl-1 pr-1">
                                  Discount Code Type
                                </th>
                              }
                              <th scope="col" className="pl-1 pr-1">
                                <Sort onClick={() => handleSort(sort, 'order__subtotal', setSortOrder, setSort)} sortingOrder={sortOrder} column="order__subtotal">
                                  Order Subtotal
                                </Sort>
                              </th>
                              <th scope="col" className="pl-1 pr-1">
                                Payment Status
                              </th>
                              <th scope="col" className="pl-1 pr-1">
                                Fulfillment
                              </th>
                              <th scope="col" className="pl-1 pr-1">
                                <Sort onClick={() => handleSort(sort, 'order__source', setSortOrder, setSort)} sortingOrder={sortOrder} column="order__source">
                                  Sales Channel
                                </Sort>
                              </th>
                              <th scope="col" className="pl-1 pr-1">
                                <Sort onClick={() => handleSort(sort, 'invoice_ref', setSortOrder, setSort)} sortingOrder={sortOrder} column="invoice_ref">Invoice</Sort>
                              </th>
                              <th scope="col" className="pl-3 pr-3">
                                <Sort onClick={() => handleSort(sort, 'billing_status', setSortOrder, setSort)} sortingOrder={sortOrder} column="billing_status">
                                  Billing Status
                                </Sort>
                              </th>
                              <th scope="col" className="pl-3 pr-3"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {chargebacks.map((chargeback) => (
                              <tr key={chargeback.id}>
                                <td className={'pr-3 pl-3'}>
                                  <input
                                    type="checkbox"
                                    name={chargeback.order_id}
                                    value={chargeback.order_id}
                                    id={'checkbox_' + chargeback.order_id}
                                    className={'chargebackCheckbox'}
                                    onChange={(e) => handleCheckbox(e, chargeback.brand_id)}
                                    defaultChecked={chargeback?.checked}
                                  />
                                </td>
                                <td style={{paddingLeft:"0.25rem", paddingRight:"1.5 rem"}}>
                                {Array.isArray(chargeback.brand_name) ?
                                  chargeback.brand_name.map((brand, index, array) => (
                                    <span key={index}>
                                      {brand}
                                      {index !== array.length - 1 ? ", " : ""}
                                      {(index + 1) % 3 === 0 && index !== array.length - 1 ? <br /> : ""}
                                    </span>
                                  ))
                                  :
                                  chargeback.brand_name
                                }
                                </td>

                                <td className="pl-1 pr-1">
                                  <a
                                    target="_blank"
                                    href={`/order-details?${new URLSearchParams({ orderId: `${chargeback.order_id}` })}`}
                                    rel="noreferrer"
                                    className="btn btn-link p-0"
                                  >
                                    {chargeback.shopify_id}
                                  </a>
                                </td>
                                <td className="pl-1 pr-1">
                                  <Moment format="MM/D/YYYY" withTitle>
                                    {chargeback.order_created_at}
                                  </Moment>
                                </td>
                                <td className="pl-1 pr-1">
                                  <Moment format="MM/D/YYYY" withTitle>
                                    {chargeback.created_at ??"-"}
                                  </Moment>
                                </td>
                                <td className="pl-1 pr-1">{chargeback?.assignee?.name ?? "-"}</td>
                                <td className="pl-1 pr-1">${chargeback.shipping_cost ?? "-"}</td>
                                <td className="pl-1 pr-1">${chargeback.shipping_paid ?? "-"}</td>
                                <td className="pl-1 pr-1">${chargeback.chargeback_amount ?? "-"}</td>
                                {user?.isAdmin && <td className="pl-1 pr-1">{chargeback.discount_code ??"-"}</td> }
                                {user?.isAdmin && <td className="pl-1 pr-1">{chargeback.discount_code_type ?? "-"}</td>}
                                <td className="pl-1 pr-1">${chargeback.order_subtotal ??"-"}</td>
                                <td className="pl-1 pr-1">
                                  <FinancialStatus>
                                    {chargeback.financial_status.financial_status ??"-"}
                                  </FinancialStatus>
                                </td>
                                <td className="pl-1 pr-1">
                                  <Fulfillment>{chargeback.global_fulfillment_status ??"-"}</Fulfillment>
                                </td>
                                <td className="pl-1 pr-1">{chargeback.sales_channel ??"-"}</td>
                                <td className="pl-1 pr-1">
                                  <a
                                    target="_blank"
                                    href={chargeback.invoice_url + '/' + chargeback.invoice_ref}
                                    className="btn btn-link p-0"
                                  >
                                    {chargeback.invoice_ref ??"-"}
                                  </a>
                                </td>
                                <td className="pl-3 pr-3">
                                  <BillingStatusColor>
                                    {chargeback.billing_status ?? "-"}
                                  </BillingStatusColor>
                                </td>
                                {user.isAdmin && (
                                  <td className="pl-3 pr-3">
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        className="btn text-primary"
                                        role="button"
                                        size="sm"
                                        color="white" 
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <i className="fas fa-ellipsis-v text-dark"></i>
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-arrow" right>
                                        <DropdownItem
                                          onClick={(e) =>
                                            handleUpdateChargebackStatusModal([chargeback])
                                          }
                                        >
                                          <i className="fas fa-file-invoice" /> Update Order Status
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <PagePagination pagination={pagination} handlePagination={handlePagination} page={page} totalCount={chargebackCount} />
                      </>
                    )}
                  </Card>
                </div>
              )}
            </Row>
          </div>
        </Container>
      ) : null}
    </>
  );
};

export default ShippingChargebacks;
