/*
    Monday Task ID - 7641942845
    Migration of Bulk Order Details(Django Page) -> React
    Author - Akshay Vishwakarma
*/

import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { api } from "services/api";
import { useToasts } from "react-toast-notifications";
function DeleteBulkItemModel({ DeleteModalIsOpen, setDeleteModalIsOpen, name, id, refreshPage }) {
    const { addToast } = useToasts();
    const toggleModel = () => {
        setDeleteModalIsOpen(!DeleteModalIsOpen);
    };

    const handleDelete = id => {
        setDeleteModalIsOpen(false);
        api.delete(`bulk-orders-item-delete/${id}/`)
            .then(response => {
                setDeleteModalIsOpen(!DeleteModalIsOpen);
                toggleModel();
                refreshPage();
                addToast("Bulk Order Item deleted successfully!", { appearance: "success", autoDismiss: true });
            })
            .catch(error => {
                toggleModel();
                addToast(error?.response?.data, { appearance: "error", autoDismiss: true });
            });
    };
    return (
        <Modal isOpen={DeleteModalIsOpen} toggle={toggleModel} size="lg">
            <ModalHeader className="bg-danger text-white">
                <span className="text-white h3">Confirm</span>
            </ModalHeader>
            <ModalBody>Are you sure you want to delete the order for {name} ?</ModalBody>
            <ModalFooter>
                <Button onClick={toggleModel}>Cancel</Button>
                <Button
                    className="bg-danger text-white"
                    onClick={() => { handleDelete(id) }}>
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>
    );
}
export default DeleteBulkItemModel;