/*
    Monday Task ID - 7641942845
    Migration of Bulk Order Details(Django Page) -> React
    Author - Akshay Vishwakarma
*/

import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { api } from "services/api";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import BulkDetails from "./BulkDetails";

const BulkOrdersDetails = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const bulkOrderId = searchParams.get("bulkOrderId");

    const { addToast } = useToasts();
    const history = useHistory();
    const [bulkOrderDetails, setBulkOrderDetails] = useState(null);

    const fetchData = async (endpoint, setterFn) => {
        try {
            const response = await api.get(endpoint);
            const data = response?.data;
            setterFn(data);
        } catch (error) {
            console.error("Error fetching data:", error);
            addToast("Error fetching data", { appearance: "success", autoDismiss: true });
            history.push("/bulk-orders");
        }
    };

    useEffect(() => {
        fetchData(`bulk-orders-details/${bulkOrderId}/`, setBulkOrderDetails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshPage = () => {
        fetchData(`bulk-orders-details/${bulkOrderId}/`, setBulkOrderDetails);
    };

    return (
        <div>
            {bulkOrderDetails ? (
                <BulkDetails bulkOrderDetails={bulkOrderDetails} refreshPage={refreshPage} />
            ) : (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>
                    <Spinner />
                </div>
            )}
        </div>
    );
};

export default BulkOrdersDetails;
